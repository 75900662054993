import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isAuthenticated } from 'logic/redux/reducers/user';
import LoadingScreen from 'components/LoadingScreen';

const LoginGuard = ({ isLoggedIn, children }) => (isLoggedIn ? children : <LoadingScreen />);

const mapStateToProps = (state) => ({
  isLoggedIn: isAuthenticated(state)
});

LoginGuard.defaultProps = {
  isLoggedIn: false
};

LoginGuard.propTypes = {
  children: PropTypes.node.isRequired,
  isLoggedIn: PropTypes.bool
};

export default connect(mapStateToProps)(LoginGuard);
