import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import styles from './AutocompleteInput.module.scss';

class AutocompleteInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      open: false
    };

    this.filterOptions = props.addOption === true ? this.filterOptionsAdd : this.filterOptionsNoAdd;
  }

  getNewValueObject(value) {
    const { customNewValueObject } = this.props;
    if (customNewValueObject) {
      return customNewValueObject(value);
    }
    return { id: null, name: value };
  }

  getAddValueObject(value) {
    return { id: null, name: ` Add ${value}`, inputValue: value };
  }

  openAutocomplete = () => this.setState({ open: true });

  closeAutocomplete = () => this.setState({ open: false });

  handleChange = (event, value, reason) => {
    const { name, onChange, delayExpand } = this.props;
    switch (reason) {
      case 'create-option':
        value = this.getNewValueObject(value);

        onChange(null, { name, value }).then(() => {
          this.loadOptions(value.name);
        });
        break;
      case 'clear':
        onChange(null, { name, value }).then(() => {
          this.loadOptions();
        });
        if (!delayExpand) {
          this.openAutocomplete();
        }
        break;
      case 'select-option':
        if (value.inputValue) {
          value = this.getNewValueObject(value.inputValue);
        }
        this.closeAutocomplete();
        onChange(null, { name, value }).then(() => {
          this.loadOptions(value.name);
        });
        break;
      case 'remove-option':
      case 'blur':
      default:
        break;
    }
  };

  handleInputChange = (event, value) => {
    const { delayExpand } = this.props;
    if (event && delayExpand && value.length >= 2) {
      this.loadOptions(value);
      this.openAutocomplete();
    } else if (event && delayExpand && value.length < 2) {
      this.closeAutocomplete();
    } else this.loadOptions(value);
  };

  handleFocus = () => {
    const { value, delayExpand } = this.props;
    if (!delayExpand) {
      this.openAutocomplete();
    }
    this.loadOptions(value === null ? '' : value.name);
  };

  handleBlur = () => {
    this.closeAutocomplete();
  };

  async loadOptions(value = '') {
    try {
      const options = await this.props.fetchItems(value);

      this.setState({ options });
    } catch (error) {
      this.setState({ options: [] });
    }
  }

  filterOptionsAdd = (options, params) => {
    if (params.inputValue !== '') {
      options.push(this.getAddValueObject(params.inputValue));
    }

    return options;
  };

  filterOptionsNoAdd = (options) => options;

  getOptionLabel = (option) => {
    if (typeof option === 'string') {
      return option;
    }

    if (option.inputValue) {
      return option.inputValue;
    }

    return option.name;
  };

  renderInput = (params) => {
    return <TextField {...params} label={this.props.label} variant="outlined" fullWidth margin="dense" />;
  };

  renderOption = (option) => {
    return (
      <div className={styles.autocompleteOption} id={`menu-item-${option.name}`}>
        {option.name}
      </div>
    );
  };

  render() {
    const {
      state: { options, open },
      props: { name, value, disabled }
    } = this;

    return (
      <div className={styles.wrapper}>
        <Autocomplete
          open={open}
          autoComplete
          freeSolo
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          disabled={disabled}
          value={value}
          name={name}
          classes={{ popperDisablePortal: styles.popperDisablePortal }}
          options={options}
          renderOption={this.renderOption}
          filterOptions={this.filterOptions}
          getOptionLabel={this.getOptionLabel}
          renderInput={this.renderInput}
          onInputChange={this.handleInputChange}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          disablePortal
        />
      </div>
    );
  }
}

AutocompleteInput.defaultProps = {
  disabled: false,
  addOption: true,
  customNewValueObject: null,
  delayExpand: false
};

AutocompleteInput.propTypes = {
  label: PropTypes.string.isRequired,
  fetchItems: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  addOption: PropTypes.bool,
  value: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  onChange: PropTypes.func.isRequired,
  customNewValueObject: PropTypes.func,
  delayExpand: PropTypes.bool
};

export default AutocompleteInput;
