import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Button as MaterialButton } from '@material-ui/core';
import styles from './Button.module.scss';

const Button = ({ label, color, isLoading, handleClick }) => (
  <MaterialButton onClick={handleClick} fullWidth color={color} variant="contained">
    {isLoading ? <CircularProgress size={30} className={styles.circularProgress} /> : label}
  </MaterialButton>
);

Button.defaultProps = {
  color: 'primary',
  isLoading: false
};

Button.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  isLoading: PropTypes.bool,
  handleClick: PropTypes.func.isRequired
};

export default Button;
