import { SET_CATEGORIES, SET_CATEGORIES_LIST } from 'logic/redux/actions/categories';

const initialState = {
  items: [],
  count: 0,
  list: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CATEGORIES:
      return {
        ...state,
        items: action.data,
        count: action.count
      };
    case SET_CATEGORIES_LIST:
      return {
        ...state,
        list: action.list
      };
    default:
      return state;
  }
}

const getStateBase = (state) => state.categories;

export const getCategories = (state) => getStateBase(state).items;
export const getCount = (state) => getStateBase(state).count;
export const getCategoriesList = (state) => getStateBase(state).list;
