import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import './logic/i18n';
import { create } from 'jss';
import 'react-notifications/lib/notifications.css';
import { ThemeProvider, createMuiTheme, StylesProvider, jssPreset } from '@material-ui/core/styles';
import { KeycloakProvider } from '@react-keycloak/web';
import keycloak from 'logic/keycloak';
import store from 'logic/redux/store';
import { loginSuccessful } from 'logic/redux/actions/user';
import globalStyles from 'assets/css/style.scss';
import App from 'template/App';

import 'fontsource-roboto';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: globalStyles.primary
    },
    secondary: {
      main: globalStyles.secondary
    }
  },
  overrides: {
    MuiTableRow: {
      root: {
        '&$selected': {
          backgroundColor: '#3f51b51a !important',
          '&$hover': {
            backgroundColor: '#3f51b51a !important'
          }
        }
      }
    }
  }
});

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point')
});

ReactDOM.render(
  <KeycloakProvider
    keycloak={keycloak}
    initConfig={{ onLoad: 'login-required' }}
    onTokens={(tokens) => store.dispatch(loginSuccessful(tokens))}
  >
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StylesProvider jss={jss}>
        <App />
      </StylesProvider>
    </ThemeProvider>
  </KeycloakProvider>,
  document.getElementById('root')
);
