import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  fetchCompanies,
  fetchCompany,
  addCompany,
  updateCompany,
  removeCompanies
} from 'logic/redux/actions/companies';
import { getCompanies, getCount } from 'logic/redux/reducers/companies';
import { ITEM_DATA_TYPE, FIELD_TYPE } from 'config/constants';
import CrudView from 'components/CrudView';

const HEADER_LABELS = [
  { columnName: 'name', sortName: 'name', label: 'Company Name', type: ITEM_DATA_TYPE.string },
  { columnName: 'website', sortName: 'website', label: 'Website', type: ITEM_DATA_TYPE.string }
];

const FIELDS = {
  name: {
    type: FIELD_TYPE.text,
    label: 'Company Name'
  },
  website: {
    type: FIELD_TYPE.text,
    label: 'Website'
  }
};

function emptyData() {
  return { name: '' };
}

function Companies(props) {
  const { companies, count, fetchCompanies, fetchCompany, addCompany, updateCompany, removeCompanies } = props;

  return (
    <CrudView
      title="Companies"
      headerLabels={HEADER_LABELS}
      pageData={companies}
      count={count}
      getItems={fetchCompanies}
      getItem={fetchCompany}
      addItem={addCompany}
      updateItem={updateCompany}
      removeItems={removeCompanies}
      emptyData={emptyData}
      fields={FIELDS}
    />
  );
}

Companies.propTypes = {
  fetchCompanies: PropTypes.func.isRequired,
  fetchCompany: PropTypes.func.isRequired,
  addCompany: PropTypes.func.isRequired,
  updateCompany: PropTypes.func.isRequired,
  removeCompanies: PropTypes.func.isRequired,
  companies: PropTypes.array,
  count: PropTypes.number
};

Companies.defaultProps = {
  companies: [],
  count: 0
};

const mapStateToProps = (state) => ({
  companies: getCompanies(state),
  count: getCount(state)
});

const mapDispatchToProps = {
  fetchCompanies,
  fetchCompany,
  addCompany,
  updateCompany,
  removeCompanies
};

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
