import { fetch } from 'logic/middleware/api';
import apiUrlByName from 'helpers/urls';

// actions
export const SET_CONTACTS = 'contact/set-contacts';

// action creators
export const setContacts = (data, count) => ({
  type: SET_CONTACTS,
  data,
  count
});

function buildUpdateContact(body) {
  const { firstName, lastName, phoneNumbers, company, contactGroup, jobDescription, email, contactOwner } = body;

  const newBody = {
    firstName,
    lastName,
    jobDescription,
    email
  };

  if (!company) {
    newBody.companyId = null;
  } else if (company.id === null) {
    newBody.companyName = company.name;
  } else {
    newBody.companyId = company.id;
  }

  newBody.phoneNumbers = phoneNumbers.map(({ id, phoneNumber, type }) => {
    if (id.slice(0, 4) === 'new_') {
      return { phoneNumber, type };
    }

    return { id, phoneNumber, type };
  });

  newBody.contactGroupId = contactGroup ? contactGroup.id : null;
  newBody.contactOwnerId = contactOwner ? contactOwner.id : null;

  return newBody;
}

function buildDisplayContact(body) {
  const {
    id,
    contactGroupId,
    companyId,
    firstName,
    lastName,
    // phoneNumber,
    company_name,
    email,
    contactOwner_name,
    contactOwnerId,
    jobDescription,
    contactGroup_name,
    PhoneNumbers
  } = body;

  return {
    id,
    data: {
      firstName,
      lastName,
      phoneNumbers: PhoneNumbers.map(({ id, phoneNumber, type, city }) => ({ id, phoneNumber, type, city })),
      jobDescription,
      email,
      contactOwner: contactOwnerId
        ? {
            id: contactOwnerId,
            name: contactOwner_name
          }
        : null,
      company: companyId
        ? {
            id: companyId,
            name: company_name
          }
        : null,
      contactGroup: contactGroupId
        ? {
            id: contactGroupId,
            name: contactGroup_name
          }
        : null
    }
  };
}

export const fetchContacts = (queryParams = {}) => async (dispatch) => {
  const result = await dispatch(fetch(apiUrlByName('contact', {}, queryParams), {}));

  const data = result.data.map((item) => buildDisplayContact(item));

  return dispatch(setContacts(data, result.totalCount));
};

export const fetchContact = (id) => async (dispatch) => {
  try {
    const result = await dispatch(fetch(apiUrlByName('contactID', { id }), {}));

    return buildDisplayContact(result);
  } catch (error) {
    return null;
  }
};

export const fetchFilteredContacts = (filter) => async (dispatch) => {
  const result = await dispatch(
    fetch(
      apiUrlByName(
        'contact',
        {},
        { limit: 8, filter_firstName: filter, filter_lastName: filter, orderBy: 'firstName', orderDirection: 'asc' }
      ),
      {}
    )
  );

  return result.data.map(
    ({
      id,
      firstName,
      lastName,
      companyId,
      company_name,
      contactGroupId,
      contactGroup_name,
      jobDescription
      // PhoneNumbers
    }) => ({
      id,
      name: `${firstName || ''} ${lastName || ''}`,
      jobDescription: jobDescription || '',
      firstName,
      lastName,
      // phoneNumbers: PhoneNumbers.map(({ id, phoneNumber, type }) => ({ id, phoneNumber, type })),
      company: companyId
        ? {
            id: companyId,
            name: company_name
          }
        : null,
      contactGroup: contactGroupId
        ? {
            id: contactGroupId,
            name: contactGroup_name
          }
        : null
    })
  );
};

export const addContact = (body) => (dispatch) => {
  const newBody = buildUpdateContact(body);

  return dispatch(
    fetch(apiUrlByName('contact'), {
      method: 'post',
      body: JSON.stringify(newBody)
    })
  );
};

export const updateContact = (id, body) => (dispatch) => {
  const newBody = buildUpdateContact(body);

  return dispatch(
    fetch(apiUrlByName('contactID', { id }), {
      method: 'put',
      body: JSON.stringify(newBody)
    })
  );
};

export const removeContacts = (ids) => (dispatch) =>
  dispatch(
    fetch(apiUrlByName('contact', {}, { ids: ids.join(',') }), {
      method: 'delete'
    })
  );

export const deletePersonalInformation = (ids) => (dispatch) =>
  dispatch(
    fetch(apiUrlByName('contact', {}, { ids: ids.join(','), type: 'personal_information' }), {
      method: 'delete'
    })
  );
