import urls from 'config/urls.json';
import isEmpty from 'lodash/isEmpty';

function ApiUrlException(message) {
  this.message = message;
  this.name = 'ApiUrlException';
}

export default (urlKey, params = {}, queryParams = {}) => {
  if (Object.keys(urls).includes(urlKey)) {
    const base = process.env.REACT_APP_API_URL;
    let route = [base, urls[urlKey]].join('');
    Object.keys(params).forEach((key) => {
      route = route.replace(`:${key}`, params[key]);
    });
    if (!isEmpty(queryParams)) {
      const queryString = Object.keys(queryParams)
        .map((key) => `${key}=${queryParams[key]}`)
        .join('&');
      route += `?${queryString}`;
    }

    return route;
  }

  throw new ApiUrlException(`route ${urlKey} not found`);
};
