import {
  HTTP_OK,
  HTTP_CREATED,
  HTTP_ACCEPTED,
  HTTP_NON_AUTHORITATIVE_INFORMATION,
  HTTP_NO_CONTENT,
  HTTP_RESET_CONTENT,
  HTTP_PARTIAL_CONTENT,
  HTTP_MULTI_STATUS,
  HTTP_ALREADY_REPORTED,
  HTTP_IM_USED,
  HTTP_CONFLICT,
  HTTP_NOT_FOUND
} from 'config/constants';
import ApiError from './ApiError';

class Rest {
  static isSuccessfulHttpStatus(status) {
    let successful = false;
    switch (status) {
      case HTTP_OK:
      case HTTP_CREATED:
      case HTTP_ACCEPTED:
      case HTTP_NON_AUTHORITATIVE_INFORMATION:
      case HTTP_NO_CONTENT:
      case HTTP_RESET_CONTENT:
      case HTTP_PARTIAL_CONTENT:
      case HTTP_MULTI_STATUS:
      case HTTP_ALREADY_REPORTED:
      case HTTP_IM_USED:
        successful = true;
        break;
      default:
        break;
    }
    return successful;
  }

  static isFailureWithBody(status) {
    let withBody = false;
    switch (status) {
      case HTTP_CONFLICT:
      case HTTP_NOT_FOUND:
        withBody = true;
        break;
      default:
        break;
    }
    return withBody;
  }

  static async requestHandling(response) {
    // TODO some more complex error handling can be added here
    try {
      const text = await response.text();
      return JSON.parse(text);
    } catch (e) {
      return null;
    }
  }

  static async fetchImplementation(url, requestOptions = {}) {
    const response = await fetch(url, requestOptions);
    if (Rest.isSuccessfulHttpStatus(response.status)) {
      return Rest.requestHandling(response);
    }
    if (Rest.isFailureWithBody(response.status)) {
      throw await Rest.requestHandling(response);
    }
    throw new ApiError(response);
  }
}

export default Rest;
