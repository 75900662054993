import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import flow from 'lodash/flow';
import { fetchOperators, fetchOperator, updateOperator } from 'logic/redux/actions/operators';
import { getOperators, getCount } from 'logic/redux/reducers/operators';
import { ITEM_DATA_TYPE, FIELD_TYPE, OPERATOR_SHAPE } from 'config/constants';
import CrudView from 'components/CrudView';

function emptyData() {
  return { sipUsername: '', username: '' };
}

const Operators = ({ t, fetchOperators, fetchOperator, updateOperator, operators, count }) => {
  const HEADER_LABELS = [
    {
      columnName: 'username',
      sortName: 'username',
      label: t('label_user_username'),
      type: ITEM_DATA_TYPE.string
    },
    {
      columnName: 'sipUsername',
      sortName: 'sipUsername',
      label: t('label_user_sipUsername'),
      type: ITEM_DATA_TYPE.string
    },
    {
      columnName: 'lastName',
      sortName: 'lastName',
      label: t('last_name_label'),
      type: ITEM_DATA_TYPE.string
    },
    {
      columnName: 'firstName',
      sortName: 'firstName',
      label: t('first_name_label'),
      type: ITEM_DATA_TYPE.string
    },
    {
      columnName: 'email',
      sortName: 'email',
      label: t('label_user_email'),
      type: ITEM_DATA_TYPE.string
    }
  ];

  const FIELDS = {
    sipUsername: {
      type: FIELD_TYPE.text,
      label: t('label_user_sipUsername')
    },
    lastName: {
      type: FIELD_TYPE.text,
      label: t('last_name_label')
    },
    firstName: {
      type: FIELD_TYPE.text,
      label: t('first_name_label')
    },
    email: {
      type: FIELD_TYPE.text,
      label: t('label_user_email')
    }
  };

  return (
    <CrudView
      title={t('operators_title')}
      headerLabels={HEADER_LABELS}
      pageData={operators}
      count={count}
      getItems={fetchOperators}
      getItem={fetchOperator}
      updateItem={updateOperator}
      emptyData={emptyData}
      fields={FIELDS}
    />
  );
};

Operators.propTypes = {
  fetchOperators: PropTypes.func.isRequired,
  fetchOperator: PropTypes.func.isRequired,
  updateOperator: PropTypes.func.isRequired,
  operators: PropTypes.arrayOf(PropTypes.shape(OPERATOR_SHAPE)),
  t: PropTypes.func.isRequired,
  count: PropTypes.number
};

Operators.defaultProps = {
  operators: [],
  count: 0
};

const mapStateToProps = (state) => ({
  operators: getOperators(state),
  count: getCount(state)
});

const mapDispatchToProps = {
  fetchOperators,
  fetchOperator,
  updateOperator
};

export default flow(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Operators);
