import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import flow from 'lodash/flow';
import { withTranslation } from 'react-i18next';
import { Button, Paper, Typography, TableBody, TableRow, TableCell, Table } from '@material-ui/core';

import ContentView from 'components/ContentView';
import { getRealtimeStatus } from 'logic/redux/reducers/settings';
import { areNotificationsAllowed, requestNotificationPermission } from 'helpers/notification';
import StatusLabel from './StatusLabel';

import styles from './Settings.module.scss';

const areNotificationsPermitted = areNotificationsAllowed();

const Settings = ({ realtimeConnection, t }) => (
  <ContentView title={t('settings_title')}>
    <Paper className={styles.paper}>
      <Typography variant="h5">Notifications</Typography>

      <Table size="medium" padding="default">
        <TableBody>
          <TableRow>
            <TableCell>Notification Status</TableCell>
            <TableCell align="left">
              <StatusLabel active={areNotificationsPermitted} />
            </TableCell>
            <TableCell>
              {!areNotificationsPermitted && (
                <Button onClick={requestNotificationPermission} fullWidth color="primary" variant="text">
                  Request
                </Button>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Realtime Call Notification Status</TableCell>
            <TableCell align="left">
              <StatusLabel active={realtimeConnection} />
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  </ContentView>
);

Settings.propTypes = {
  realtimeConnection: PropTypes.bool,
  t: PropTypes.func.isRequired
};

Settings.defaultProps = { realtimeConnection: false };

const mapStateToProps = (state) => ({ realtimeConnection: getRealtimeStatus(state) });

export default flow(withTranslation(), connect(mapStateToProps))(Settings);
