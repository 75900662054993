import { SET_OPERATORS } from 'logic/redux/actions/operators';

const initialState = {
  items: [],
  count: 0
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_OPERATORS:
      return {
        ...state,
        items: action.data,
        count: action.count
      };
    default:
      return state;
  }
}

const getStateBase = (state) => state.operators;

export const getOperators = (state) => getStateBase(state).items;
export const getCount = (state) => getStateBase(state).count;
