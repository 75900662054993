import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '@material-ui/core';
import {
  DriveEta as AutoIcon,
  Home as HomeIcon,
  PhoneAndroid as MobileIcon,
  Business as OfficeIcon,
  PhonelinkRing as PagerIcon,
  DeviceUnknown as UnknownIcon
} from '@material-ui/icons';
import { PHONE_NUMBER_TYPE } from 'config/constants';
import styles from './CustomPhoneType.module.scss';

function CustomPhoneType({ type, title }) {
  title = title ? ` - ${title}` : '';

  switch (type) {
    case 'auto':
      return (
        <Tooltip title={`${PHONE_NUMBER_TYPE[type]}${title}`}>
          <AutoIcon color="primary" fontSize="small" className={styles.icon} />
        </Tooltip>
      );
    case 'home':
      return (
        <Tooltip title={`${PHONE_NUMBER_TYPE[type]}${title}`}>
          <HomeIcon color="primary" fontSize="small" className={styles.icon} />
        </Tooltip>
      );
    case 'mobile':
      return (
        <Tooltip title={`${PHONE_NUMBER_TYPE[type]}${title}`}>
          <MobileIcon color="primary" fontSize="small" className={styles.icon} />
        </Tooltip>
      );
    case 'office':
      return (
        <Tooltip title={`${PHONE_NUMBER_TYPE[type]}${title}`}>
          <OfficeIcon color="primary" fontSize="small" className={styles.icon} />
        </Tooltip>
      );
    case 'pager':
      return (
        <Tooltip title={`${PHONE_NUMBER_TYPE[type]}${title}`}>
          <PagerIcon color="primary" fontSize="small" className={styles.icon} />
        </Tooltip>
      );
    case 'none':
    default:
      type = type ? `: ${type}` : '';
      return (
        <Tooltip title={`Unknown${type}${title}`}>
          <UnknownIcon color="action" fontSize="small" className={styles.icon} />
        </Tooltip>
      );
  }
}

CustomPhoneType.defaultProps = {
  type: '',
  title: ''
};

CustomPhoneType.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string
};

export default CustomPhoneType;
