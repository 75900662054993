import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withKeycloak } from '@react-keycloak/web';

import Dashboard from 'sections/Dashboard';
import Contacts from 'sections/Contacts';
import Calls from 'sections/Calls';
import Companies from 'sections/Companies';
import Profile from 'sections/Profile';
import Settings from 'sections/Settings';
import Categories from 'sections/Attributes/Categories';
import Groups from 'sections/Attributes/Groups';
import Operators from 'sections/Attributes/Operators';

import ProtectedRoute from 'components/ProtectedRoute';

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Dashboard} />

    <Route exact path="/contacts" component={Contacts} />

    <Route exact path="/calls" component={Calls} />
    <Route exact path="/calls/:id" component={Calls} />

    <Route exact path="/companies" component={Companies} />
    <Route exact path="/profile" component={Profile} />
    <Route exact path="/settings" component={Settings} />

    <ProtectedRoute roles={['app-admin']} exact path="/attributes/categories" component={Categories} />
    <ProtectedRoute roles={['app-admin']} exact path="/attributes/operators" component={Operators} />
    <ProtectedRoute roles={['app-admin']} exact path="/attributes/groups" component={Groups} />
  </Switch>
);

export default withKeycloak(Routes);
