// Action creators
import set from 'lodash/set';
import get from 'lodash/get';
// import moment from 'moment';
import { API_HEADERS } from 'config/constants';

export const fetch = (url, options = { method: 'get' }, config = {}) => ({
  type: 'API_FETCH',
  url,
  ...options,
  config
});

// Middleware

export default (fetchImplementation) => (store) => (next) => (action) => {
  if (action.type === 'API_FETCH') {
    const { url, config, ...options } = action;
    let headers = get(options, 'headers', {});
    headers = { ...headers, ...API_HEADERS };
    set(options, 'headers', headers);

    // authentication
    const accessToken = get(store.getState(), 'user.accessToken');
    if (accessToken) {
      set(options, 'headers.Authorization', `bearer ${accessToken}`);
    }

    return fetchImplementation(url, options);
  }

  return next(action);
};
