import PropTypes from 'prop-types';

// HTTP Status codes
export const HTTP_OK = 200;
export const HTTP_CREATED = 201;
export const HTTP_ACCEPTED = 202;
export const HTTP_NON_AUTHORITATIVE_INFORMATION = 203;
export const HTTP_NO_CONTENT = 204;
export const HTTP_RESET_CONTENT = 205;
export const HTTP_PARTIAL_CONTENT = 206;
export const HTTP_MULTI_STATUS = 207;
export const HTTP_ALREADY_REPORTED = 208;
export const HTTP_IM_USED = 226;

export const HTTP_BAD_REQUEST = 400;
export const HTTP_UNAUTHORIZED = 401;
export const HTTPS_STATUS_FORBIDDEN = 403;
export const HTTP_NOT_FOUND = 404;
export const HTTP_METHOD_NOT_ALLOWED = 405;
export const HTTP_CONFLICT = 409;
export const HTTP_UNPROCESSABLE_ENTITY = 422;
export const HTTP_INTERNAL_SERVER_ERROR = 500;
export const HTTP_NOT_IMPLEMENTED = 501;

export const API_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8'
};

export const POLLING_INTERVAL = 20000;

// types
export const ITEM_DATA_TYPE = {
  string: 1,
  number: 2,
  date: 3,
  datetime: 4,
  time: 5,
  object: 6,
  callStatus: 7,
  phoneNumber: 8
};

export const FIELD_TYPE = {
  text: 1,
  select: 2,
  time: 3,
  autocomplete: 4,
  phoneNumber: 5,
  multiPhoneNumber: 6
};

export const CALL_DIRECTION_TYPE = {
  in: 'Incoming',
  out: 'Outgoing'
};

export const CALL_STATUS_TYPE = {
  answered: 'Answered',
  missed: 'Missed',
  ringing: 'Ringing',
  completed: 'Completed'
};

export const PHONE_NUMBER_TYPE = {
  office: 'Office',
  mobile: 'Mobile',
  auto: 'Auto',
  home: 'Home',
  pager: 'Pager'
};

export const EMPTY_PARSED_PHONE_NUMBER = {
  nationalNumber: null,
  countryCallingCode: null
};

export const DATE_TIME_FORMAT = 'HH:mm\xa0\xa0\xa0\xa0DD.MM.YYYY';

export const TIME_FORMAT = 'HH:mm';

export const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY';

export const DATE_FORMAT_WITH_DAY_NAME = 'DD.MM.YYYY [(]dddd[)]';

// shapes
export const KEYCLOAK_SHAPE = {
  logout: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  hasRealmRole: PropTypes.func.isRequired,
  hasResourceRole: PropTypes.func.isRequired
};

export const HISTORY_SHAPE = {
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired
};

export const PROFILE_SHAPE = {
  lastName: PropTypes.string,
  firstName: PropTypes.string,
  sipUsername: PropTypes.string
};

export const OPERATOR_SHAPE = {
  sipUsername: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired
};
