// import { fetch } from 'logic/middleware/api';
// import apiUrlByName from 'helpers/urls';
import COUNTRY_CODES from 'config/countryCodes.json';

// actions
export const SET_COUNTRY_CODES = 'resources/set-countryCodes';

// action creators
export const setCountryCodes = (countryCodes) => ({
  type: SET_COUNTRY_CODES,
  countryCodes
});

export const fetchCountryCodes = (queryParams = {}) => async (dispatch) => {
  // const result = await dispatch(fetch(apiUrlByName('resources', {}, queryParams), {}));

  return dispatch(setCountryCodes(COUNTRY_CODES));
};

export const fetchFilteredCountryCode = (filter) => (dispatch, getState) => {
  const { countryCodes } = getState().resources;

  if (filter) {
    return countryCodes.filter(
      (item) =>
        item.name.toLowerCase().includes(filter.toLowerCase()) ||
        String(item.id).toLowerCase().includes(filter.toLowerCase())
    );
  }

  return countryCodes;
};
