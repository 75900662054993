import { LOGIN_SUCCESSFUL, PROFILE_LOADED, PROFILE_CREATE_ERROR } from 'logic/redux/actions/user';

const defaultState = {
  accessToken: null,
  profile: {
    sipUsername: null
  }
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case LOGIN_SUCCESSFUL:
      return {
        ...state,
        accessToken: action.tokens.token
      };
    case PROFILE_LOADED: {
      return {
        ...state,
        profile: action.profile
      };
    }
    case PROFILE_CREATE_ERROR: {
      return {
        ...state,
        profileCreationError: true
      };
    }
    default:
      return state;
  }
}

const getStateBase = (state) => state.user;

export const isAuthenticated = (state) => !!getStateBase(state).accessToken;
export const getProfile = (state) => getStateBase(state).profile;
