import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  fetchCategories,
  fetchCategory,
  addCategory,
  updateCategory,
  removeCategories
} from 'logic/redux/actions/categories';
import { getCategories, getCount } from 'logic/redux/reducers/categories';
import { ITEM_DATA_TYPE, FIELD_TYPE } from 'config/constants';
import CrudView from 'components/CrudView';

const HEADER_LABELS = [{ columnName: 'name', sortName: 'name', label: 'Category Name', type: ITEM_DATA_TYPE.string }];

const FIELDS = {
  name: {
    type: FIELD_TYPE.text,
    label: 'Category Name'
  }
};

const STATE_OVERRIDE = {
  orderBy: 'name',
  orderDirection: 'asc'
};

function emptyData() {
  return { name: '' };
}

function Categories(props) {
  const { categories, count, fetchCategories, fetchCategory, addCategory, updateCategory, removeCategories } = props;

  return (
    <CrudView
      title="Call categories"
      headerLabels={HEADER_LABELS}
      pageData={categories}
      count={count}
      getItems={fetchCategories}
      getItem={fetchCategory}
      addItem={addCategory}
      updateItem={updateCategory}
      removeItems={removeCategories}
      emptyData={emptyData}
      fields={FIELDS}
      stateOverride={STATE_OVERRIDE}
    />
  );
}

Categories.propTypes = {
  fetchCategories: PropTypes.func.isRequired,
  fetchCategory: PropTypes.func.isRequired,
  addCategory: PropTypes.func.isRequired,
  updateCategory: PropTypes.func.isRequired,
  removeCategories: PropTypes.func.isRequired,
  categories: PropTypes.array,
  count: PropTypes.number
};

Categories.defaultProps = {
  categories: [],
  count: 0
};

const mapStateToProps = (state) => ({
  categories: getCategories(state),
  count: getCount(state)
});

const mapDispatchToProps = {
  fetchCategories,
  fetchCategory,
  addCategory,
  updateCategory,
  removeCategories
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
