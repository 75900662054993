import { fetch } from 'logic/middleware/api';
import apiUrlByName from 'helpers/urls';

// actions
export const SET_CATEGORIES = 'category/set-categories';
export const SET_CATEGORIES_LIST = 'category/set-categories-list';

// action creators
export const setCategories = (data, count) => ({
  type: SET_CATEGORIES,
  data,
  count
});

export const setCategoriesList = (list) => ({
  type: SET_CATEGORIES_LIST,
  list
});

function buildDisplayCategory(body) {
  const { id, name } = body;

  return { id, data: { name } };
}

export const fetchCategories = (queryParams = {}) => async (dispatch) => {
  const result = await dispatch(fetch(apiUrlByName('category', {}, queryParams), {}));

  const data = result.data.map((item) => buildDisplayCategory(item));

  return dispatch(setCategories(data, result.totalCount));
};

export const fetchCategory = (id) => async (dispatch) => {
  try {
    const result = await dispatch(fetch(apiUrlByName('categoryID', { id }), {}));

    return buildDisplayCategory(result);
  } catch (error) {
    return null;
  }
};

export const fetchCategoriesList = (queryParams = {}) => async (dispatch) => {
  const result = await dispatch(fetch(apiUrlByName('category', {}, queryParams), {}));

  const data = result.data.map(({ id, name }) => ({ id, name }));

  return dispatch(setCategoriesList(data));
};

export const addCategory = (body) => (dispatch) =>
  dispatch(
    fetch(apiUrlByName('category'), {
      method: 'post',
      body: JSON.stringify(body)
    })
  );

export const updateCategory = (id, body) => (dispatch) =>
  dispatch(
    fetch(apiUrlByName('categoryID', { id }), {
      method: 'put',
      body: JSON.stringify(body)
    })
  );

export const removeCategories = (ids) => (dispatch) =>
  dispatch(
    fetch(apiUrlByName('category', {}, { ids: ids.join(',') }), {
      method: 'delete'
    })
  );
