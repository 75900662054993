import { SET_COMPANIES } from 'logic/redux/actions/companies';

const initialState = {
  items: [],
  count: 0
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_COMPANIES:
      return {
        ...state,
        items: action.data,
        count: action.count
      };
    default:
      return state;
  }
}

const getStateBase = (state) => state.companies;

export const getCompanies = (state) => getStateBase(state).items;
export const getCount = (state) => getStateBase(state).count;
