import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import Rest from 'logic/Rest';
import rootReducer from 'logic/redux/reducers';

// Middlewares
import apiMiddleware from 'logic/middleware/api';

let composedMiddlewares = applyMiddleware(apiMiddleware(Rest.fetchImplementation), thunk);

if (process.env.NODE_ENV === 'development') {
  composedMiddlewares = compose(
    composedMiddlewares,
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() : (f) => f
  );
}

const store = createStore(rootReducer, undefined, composedMiddlewares);

export default store;
