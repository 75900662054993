import React from 'react';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import ProfileMenu from './ProfileMenu';

import styles from './Header.module.scss';

const Header = () => (
  <AppBar className={styles.appBar} position="fixed">
    <Toolbar>
      <img src="/salesviewer-logo-basic.png" alt="logo" className={styles.logo} />

      <Typography className={styles.topBar} color="inherit" variant="h5" noWrap>
        Sales<span className={styles.halfName}>Viewer</span>
        <sup className={styles.copyright}>®</sup> - PhoneBook
      </Typography>

      <ProfileMenu />
    </Toolbar>
  </AppBar>
);

export default Header;
