import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Typography,
  Button,
  IconButton,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress
} from '@material-ui/core';
import { ExitToApp as HideDrawerIcon } from '@material-ui/icons';

import DrawerView from 'components/DrawerView';
import AutocompleteInput from 'components/AutocompleteInput';
import TimeInput from 'components/TimeInput';
import PhoneInput from 'components/PhoneInput';
import PhoneMultiInput from 'components/PhoneMultiInput';

import { FIELD_TYPE } from 'config/constants';

import styles from './CrudView.module.scss';

class SideDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      data: props.emptyData()
    };

    this.node = createRef();
  }

  componentDidMount() {
    if (this.props.currentItemInfo) {
      this.setState(this.props.currentItemInfo);
    }

    // add when mounted
    document.addEventListener('mousedown', this.handleOutsideClick);
  }

  componentDidUpdate(prevProps) {
    const prevItem = prevProps.currentItemInfo;
    const { currentItemInfo, emptyData } = this.props;

    if ((prevItem === null && currentItemInfo) || (prevItem && currentItemInfo && prevItem.id !== currentItemInfo.id)) {
      this.setState(currentItemInfo);
    } else if (prevItem && currentItemInfo === null) {
      this.setState({ id: null, data: emptyData() });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick);
  }

  handleOutsideClick = (e) => {
    if (!this.node.current.contains(e.target) && !e.target.id.startsWith('menu-item')) {
      this.props.onClose();
    }
  };

  handleChange = async (event, valueObject) => {
    const { name, value } = event ? event.target : valueObject;
    const {
      state: { data },
      props: { fields, onFieldsChange, fieldsChanged }
    } = this;

    data[name] = value;

    if (fields[name].hasOwnProperty('updateAssociatedFields')) {
      fields[name].updateAssociatedFields.forEach((item) => {
        data[item] = value && value[item] ? value[item] : null;
      });
    }

    if (fieldsChanged) {
      this.setState({ data });
    } else {
      this.setState({ data }, () => {
        onFieldsChange(true);
      });
    }
  };

  handleChangeSelect = (event) => {
    const { name, value } = event.target;
    const valueName = event.currentTarget.dataset.name;

    this.handleChange(null, { name, value: { id: value, name: valueName } });
  };

  handleSave = () => {
    const {
      state: { id, data },
      props: { onSave }
    } = this;

    onSave({ id, data });
  };

  parentCheck = (parentNames, alwaysShow) => {
    if (parentNames) {
      const values = parentNames.map((name) => this.state.data[name]).filter((item) => item);
      if (values.length === 0 && alwaysShow) {
        return { disabled: false, hidden: false };
      }
      if (values.length === 0) {
        return { disabled: true, hidden: true };
      }
      if (values.length > 0 && values.filter((item) => item.id).length === values.length) {
        return { disabled: true, hidden: false };
      }
    }

    return { disabled: false, hidden: false };
  };

  render() {
    const {
      state: { data, id },
      props: { showDrawer, loading, fieldsChanged, fields, disableForm, onClose }
    } = this;

    return (
      <div className={classNames(styles.drawer, showDrawer ? styles.sideDrawerBackdrop : null)}>
        <div ref={this.node}>
          <DrawerView open={showDrawer}>
            <div style={{ display: 'flex', marginBottom: 16 }}>
              <IconButton color="primary" onClick={onClose} title="Close Side Drawer">
                <HideDrawerIcon />
              </IconButton>

              <Typography variant="h5" align="center" style={{ flexGrow: 1 }}>
                {id ? 'Edit' : 'New'}
              </Typography>
            </div>

            {loading ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </div>
            ) : (
              Object.keys(fields).map((fieldName) => {
                const field = fields[fieldName];

                if (field.hasOwnProperty('shouldDisplay') && !field.shouldDisplay(data)) {
                  return null;
                }

                const { disabled, hidden } = this.parentCheck(field.parentFields, field.alwaysShow);

                if (hidden) {
                  return null;
                }

                switch (field.type) {
                  case FIELD_TYPE.text:
                    return (
                      <TextField
                        key={`sideDrawer_${fieldName}`}
                        disabled={disableForm || disabled}
                        label={field.label}
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        name={fieldName}
                        value={data[fieldName] || ''}
                        onChange={this.handleChange}
                      />
                    );
                  case FIELD_TYPE.select:
                    return (
                      <FormControl key={`sideDrawer_${fieldName}`} variant="outlined" fullWidth margin="dense">
                        <InputLabel id={`${field.label}Label`}>{field.label}</InputLabel>
                        <Select
                          labelId={`${field.label}Label`}
                          disabled={disableForm || disabled}
                          label={field.label}
                          name={fieldName}
                          value={data[fieldName] && data[fieldName].id ? data[fieldName].id : ''}
                          onChange={this.handleChangeSelect}
                          MenuProps={{
                            disablePortal: true
                          }}
                        >
                          {field.showNone && (
                            <MenuItem value={null} data-name={null} id="menu-item-null">
                              None
                            </MenuItem>
                          )}
                          {field.options.map((option) => (
                            <MenuItem
                              key={option.id}
                              value={option.id}
                              data-name={option.name}
                              id={`menu-item-${option.id}`}
                            >
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    );
                  case FIELD_TYPE.time:
                    return (
                      <TimeInput
                        key={`sideDrawer_${fieldName}`}
                        disabled={disableForm || disabled}
                        label={field.label}
                        name={fieldName}
                        value={data[fieldName] || ''}
                        onChange={this.handleChange}
                      />
                    );
                  case FIELD_TYPE.autocomplete: {
                    let fieldValue = data[fieldName];
                    if (field.defaultValue && !data[fieldName]) {
                      fieldValue = field.defaultValue;
                      data[fieldName] = field.defaultValue;
                    }

                    return (
                      <AutocompleteInput
                        key={`sideDrawer_${fieldName}`}
                        disabled={disableForm || disabled}
                        addOption={field.addOption}
                        label={field.label}
                        fetchItems={field.action}
                        delayExpand={field.delayExpand}
                        name={fieldName}
                        value={fieldValue}
                        onChange={this.handleChange}
                        id="autocomplete-input"
                        customNewValueObject={field.customNewValueObject}
                      />
                    );
                  }

                  case FIELD_TYPE.phoneNumber:
                    const { disableTypeCheck } = field;
                    let disableType = false;

                    if (disableTypeCheck) {
                      disableType = !disableTypeCheck({ data, id });
                    }

                    return (
                      <PhoneInput
                        key={`sideDrawer_${fieldName}`}
                        disabled={disableForm || disabled}
                        disableType={disableType}
                        label={field.label}
                        name={fieldName}
                        value={data[fieldName]}
                        onChange={this.handleChange}
                      />
                    );
                  case FIELD_TYPE.multiPhoneNumber:
                    return (
                      <PhoneMultiInput
                        key={`sideDrawer_${fieldName}`}
                        disabled={disableForm || disabled}
                        label={field.label}
                        name={fieldName}
                        value={data[fieldName]}
                        onChange={this.handleChange}
                      />
                    );
                  default:
                    return null;
                }
              })
            )}
            <br />
            <Button onClick={this.handleSave} fullWidth color="primary" variant="contained" disabled={!fieldsChanged}>
              Save
            </Button>
          </DrawerView>
        </div>
      </div>
    );
  }
}

SideDrawer.defaultProps = {
  disableForm: false
};

SideDrawer.propTypes = {
  loading: PropTypes.bool.isRequired,
  currentItemInfo: PropTypes.object,
  emptyData: PropTypes.func.isRequired,
  fields: PropTypes.objectOf(PropTypes.object).isRequired,
  showDrawer: PropTypes.bool.isRequired,
  fieldsChanged: PropTypes.bool.isRequired,
  disableForm: PropTypes.bool,
  onFieldsChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default SideDrawer;
