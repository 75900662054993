import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Search as SearchIcon, Clear as ClearIcon } from '@material-ui/icons';

import styles from './SearchField.module.scss';

class SearchField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchString: ''
    };
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;

    if (prevProps.value !== value) {
      this.setState({ searchString: value });
    }
  }

  handleKeyUp = ({ key }) => {
    const {
      props: { onSubmit },
      state: { searchString }
    } = this;

    if (key === 'Enter') {
      onSubmit(searchString);
    }
  };

  handleChange = (event) => {
    this.setState({ searchString: event.target.value });
  };

  handleClear = () => {
    const {
      state: { searchString },
      props: { value, onClear }
    } = this;

    if (value !== searchString) {
      this.setState({ searchString: '' }, onClear);
    } else {
      onClear();
    }
  };

  render() {
    const { searchString } = this.state;

    return (
      <TextField
        variant="outlined"
        placeholder="Search..."
        margin="dense"
        size="small"
        className={styles.searchForm}
        onChange={this.handleChange}
        onKeyUp={this.handleKeyUp}
        value={searchString}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="action" />
            </InputAdornment>
          ),
          endAdornment: searchString.length > 0 && (
            <InputAdornment>
              <IconButton color="primary" onClick={this.handleClear}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    );
  }
}

SearchField.defaultProps = {
  value: ''
};

SearchField.propTypes = {
  value: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired
};

export default SearchField;
