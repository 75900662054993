import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

import { Info as InfoIcon, Error as ErrorIcon, Warning as WarningIcon } from '@material-ui/icons';

import styles from './CustomDialog.module.scss';

class CustomDialog extends Component {
  get dialogIcon() {
    switch (this.props.type) {
      default:
      case 'info':
        return <InfoIcon color="primary" style={{ width: 36, height: 36, marginRight: 8, marginTop: 16 }} />;
      case 'warning':
        return <WarningIcon color="action" />;
      case 'error':
        return <ErrorIcon color="error" />;
    }
  }

  handleClose = () => {
    this.props.onCancel();
  };

  handleConfirm = () => {
    this.props.onConfirm();
  };

  render() {
    const { title, open, content } = this.props;

    return (
      <Dialog open={open} onClose={this.handleClose}>
        <DialogTitle>
          {this.dialogIcon}
          <span style={{ paddingBottom: 8 }}>{title}</span>
        </DialogTitle>

        <DialogContent className={styles.dialogContent}>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button color="secondary" onClick={this.handleClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={this.handleConfirm}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

CustomDialog.propTypes = {
  title: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  content: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['info', 'warning', 'error']),
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default CustomDialog;
