import React from 'react';
import { LinearProgress } from '@material-ui/core';
import styles from './LoadingScreen.module.scss';

const LoadingScreen = () => (
  <div className={styles.background}>
    <div className={styles.contentWrapper}>
      <span className={styles.appName}>
        <img src="/salesviewer-logo-basic.png" alt="logo" className={styles.logo} />
        Sales<span className={styles.halfName}>Viewer</span>
        <sup className={styles.copyright}>®</sup> - PhoneBook
      </span>

      <LinearProgress variant="indeterminate" className={styles.loadingIndicator} />
    </div>
  </div>
);

export default LoadingScreen;
