import { fetch } from 'logic/middleware/api';
import apiUrlByName from 'helpers/urls';

// actions
export const LOGIN_SUCCESSFUL = 'user/login-successful';
export const PROFILE_LOADED = 'user/profile-loaded';
export const PROFILE_CREATE_ERROR = 'user/profile-create-error';

// action creators
export const loginSuccessful = (tokens) => ({
  type: LOGIN_SUCCESSFUL,
  tokens
});

export const errorCreatingProfile = () => ({
  type: PROFILE_CREATE_ERROR
});

export const profileLoaded = (profile) => ({
  type: PROFILE_LOADED,
  profile
});

const createProfile = () => async (dispatch) => {
  try {
    const profile = await dispatch(fetch(apiUrlByName('profile'), { method: 'POST' }));
    return dispatch(profileLoaded(profile));
  } catch (error) {
    return dispatch(errorCreatingProfile());
  }
};

export const fetchProfile = () => async (dispatch) => {
  try {
    const profile = await dispatch(fetch(apiUrlByName('profile'), {}));
    return dispatch(profileLoaded(profile));
  } catch (error) {
    if (error.key === 'userProfileNotFound') {
      return dispatch(createProfile());
    }
  }
};

export const updateProfile = ({ sipUsername, email }) => async (dispatch) => {
  const profile = await dispatch(
    fetch(apiUrlByName('profile'), { method: 'PUT', body: JSON.stringify({ sipUsername, email }) })
  );
  return dispatch(profileLoaded(profile));
};
