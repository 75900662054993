import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Tooltip, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';

function NavigationItem({ link, icon, title, ...otherProps }) {
  return (
    <Tooltip title={title} placement="bottom-end" enterDelay={400}>
      <MenuItem component={NavLink} to={link} exact button {...otherProps}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={title} />
      </MenuItem>
    </Tooltip>
  );
}

NavigationItem.propTypes = {
  link: PropTypes.string.isRequired,
  icon: PropTypes.node,
  title: PropTypes.string.isRequired
};

export default NavigationItem;
