import { SET_GROUPS, SET_GROUPS_LIST } from 'logic/redux/actions/groups';

const initialState = {
  items: [],
  count: 0,
  list: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_GROUPS:
      return {
        ...state,
        items: action.data,
        count: action.count
      };
    case SET_GROUPS_LIST:
      return {
        ...state,
        list: action.list
      };
    default:
      return state;
  }
}

const getStateBase = (state) => state.groups;

export const getGroups = (state) => getStateBase(state).items;
export const getCount = (state) => getStateBase(state).count;
export const getGroupsList = (state) => getStateBase(state).list;
