import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ContentView from 'components/ContentView';
import { connect } from 'react-redux';
import flow from 'lodash/flow';
import { withTranslation } from 'react-i18next';
import { Paper, TextField, Typography } from '@material-ui/core';
import { PROFILE_SHAPE } from 'config/constants';
import { getProfile } from 'logic/redux/reducers/user';
import { updateProfile } from 'logic/redux/actions/user';
import Notification from 'logic/Notification';
import Button from 'components/Button';
import styles from './Profile.module.scss';

// TODO: Separate into container and presentational
class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false };
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  };

  handleSave = () => {
    const { sipUsername, email } = this.state;

    try {
      this.setState({ isLoading: true }, async () => {
        await this.props.updateProfile({ sipUsername, email });
        Notification.successUpdate(this.props.t('profile_title'));
        setTimeout(() => window.location.reload(), 500);
      });
    } catch (e) {
      Notification.failureUpdate(this.props.t('profile_title'));
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const {
      state: { isLoading, sipUsername, email },
      props: { profile, t }
    } = this;

    return (
      <ContentView title="Profile">
        <Paper className={styles.paper}>
          <Typography variant="h5">General Information</Typography>

          <div className={styles.form}>
            <TextField
              label="SIP Provider username"
              variant="outlined"
              margin="dense"
              name="sipUsername"
              defaultValue={profile.sipUsername}
              value={sipUsername}
              onChange={this.handleChange}
            />
            <TextField
              label={t('label_user_email')}
              variant="outlined"
              margin="dense"
              name="email"
              defaultValue={profile.email}
              value={email}
              onChange={this.handleChange}
            />

            <div className={styles.button}>
              <Button color="primary" isLoading={isLoading} label={t('generic_save')} handleClick={this.handleSave} />
            </div>
          </div>
        </Paper>
      </ContentView>
    );
  }
}

Profile.propTypes = {
  profile: PropTypes.shape(PROFILE_SHAPE).isRequired,
  updateProfile: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

Profile.defaultProps = {};

const mapStateToProps = (state) => ({ profile: getProfile(state) });

const mapDispatchToProps = {
  updateProfile
};

export default flow(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Profile);
