import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import ReactPhoneInput from 'react-phone-input-2';
import de from 'react-phone-input-2/lang/de.json';
import { InputLabel, FormControl, Select, MenuItem } from '@material-ui/core';
import CustomPhoneType from 'components/CustomPhoneType';
import { PHONE_NUMBER_TYPE } from 'config/constants';
import { getPhoneNumberType } from 'helpers/phoneNumber';

import styles from './PhoneInput.module.scss';
import 'react-phone-input-2/lib/material.css';
import './PhoneInputOverride.scss';

class PhoneInput extends Component {
  constructor(props) {
    super(props);
    this.options = Object.keys(PHONE_NUMBER_TYPE);
    this.state = {
      type: getPhoneNumberType(props.value.phoneNumber, props.value.type)
    };
  }

  handleChangePhoneNumber = (phoneNumber) => {
    const { name, value: oldValue, onChange, type } = this.props;
    const phoneNumberType = getPhoneNumberType(phoneNumber, type);
    this.setState({ type: phoneNumberType });
    onChange(null, { name, value: { ...oldValue, phoneNumber, type: phoneNumberType } });
  };

  handleChangeType = (event) => {
    const { name, value: oldValue, onChange } = this.props;
    const phoneNumberType = getPhoneNumberType(oldValue.phoneNumber, event.target.value);
    this.setState({ type: phoneNumberType });
    onChange(null, { name, value: { ...oldValue, type: event.target.value } });
  };

  handleCopy = (event) => {
    const value = `+${event.target.value.replace(/[^0-9]+/g, '')}`;

    event.clipboardData.setData('text/plain', value);
    event.preventDefault();
  };

  render() {
    const {
      label,
      value: { phoneNumber, type },
      t,
      flexGrow,
      disabled,
      disableType
    } = this.props;

    const { type: phoneType } = this.state;

    return (
      <div
        className={classNames(styles.wrapper, {
          [styles.flexGrow]: flexGrow
        })}
      >
        <InputLabel shrink variant="outlined" className={styles.label}>
          {label}
        </InputLabel>

        <div className={styles.fieldsContainer}>
          <ReactPhoneInput
            country="de"
            enableLongNumbers
            localization={de}
            priority={{ de: 0, at: 1, ch: 2, us: 3 }}
            preferredCountries={['de', 'ch', 'at', 'us']}
            enableSearch
            disabled={disabled}
            value={phoneNumber}
            placeholder={t('phone_input_placeholder')}
            onChange={this.handleChangePhoneNumber}
            inputClass={styles.input}
            inputProps={{
              name: 'phone',
              // override onCopy due to firefox bug
              onCopy: this.handleCopy
            }}
          />

          <FormControl variant="outlined" className={styles.formControl}>
            <InputLabel id={`${label}Label`}>Type</InputLabel>
            {!disableType && (
              <Select
                labelId={`${label}Label`}
                disabled={disabled}
                label="Type"
                name="type"
                value={phoneType}
                onChange={this.handleChangeType}
                MenuProps={{
                  disablePortal: true
                }}
                classes={{
                  outlined: styles.selectOverride
                }}
              >
                <MenuItem value="none" id="menu-item-none">
                  <CustomPhoneType type="none" />
                </MenuItem>
                {this.options.map((option) => (
                  <MenuItem key={option} value={option} id={`menu-item-${option}`}>
                    <CustomPhoneType type={option} />
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </div>
      </div>
    );
  }
}

PhoneInput.defaultProps = {
  label: '',
  flexGrow: false,
  disabled: false,
  disableType: false
};

PhoneInput.propTypes = {
  value: PropTypes.shape({ phoneNumber: PropTypes.string, type: PropTypes.string }).isRequired,
  phoneNumber: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  flexGrow: PropTypes.bool,
  disabled: PropTypes.bool,
  disableType: PropTypes.bool
};

export default withTranslation()(PhoneInput);
