import { SET_REALTIME_STATUS } from 'logic/redux/actions/settings';

const initialState = {
  realtime: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_REALTIME_STATUS:
      return {
        ...state,
        realtime: action.active
      };
    default:
      return state;
  }
}

const getStateBase = (state) => state.settings;

export const getRealtimeStatus = (state) => getStateBase(state).realtime;
