import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchGroups, fetchGroup, addGroup, updateGroup, removeGroups } from 'logic/redux/actions/groups';
import { getGroups, getCount } from 'logic/redux/reducers/groups';
import { ITEM_DATA_TYPE, FIELD_TYPE } from 'config/constants';
import CrudView from 'components/CrudView';

const HEADER_LABELS = [{ columnName: 'name', sortName: 'name', label: 'Group Name', type: ITEM_DATA_TYPE.string }];

const FIELDS = {
  name: {
    type: FIELD_TYPE.text,
    label: 'Group Name'
  }
};

function emptyData() {
  return { name: '' };
}

function Groups(props) {
  const { groups, count, fetchGroups, fetchGroup, addGroup, updateGroup, removeGroups } = props;

  return (
    <CrudView
      title="Contact Groups"
      headerLabels={HEADER_LABELS}
      pageData={groups}
      count={count}
      getItems={fetchGroups}
      getItem={fetchGroup}
      addItem={addGroup}
      updateItem={updateGroup}
      removeItems={removeGroups}
      emptyData={emptyData}
      fields={FIELDS}
    />
  );
}

Groups.propTypes = {
  fetchGroups: PropTypes.func.isRequired,
  fetchGroup: PropTypes.func.isRequired,
  addGroup: PropTypes.func.isRequired,
  updateGroup: PropTypes.func.isRequired,
  removeGroups: PropTypes.func.isRequired,
  groups: PropTypes.array,
  count: PropTypes.number
};

Groups.defaultProps = {
  groups: [],
  count: 0
};

const mapStateToProps = (state) => ({
  groups: getGroups(state),
  count: getCount(state)
});

const mapDispatchToProps = {
  fetchGroups,
  fetchGroup,
  addGroup,
  updateGroup,
  removeGroups
};

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
