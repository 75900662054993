import React, { Component } from 'react';
import PropTypes from 'prop-types';
import flow from 'lodash/flow';
import { connect } from 'react-redux';
import { withKeycloak } from '@react-keycloak/web';
import { KEYCLOAK_SHAPE } from 'config/constants';
import { fetchCategoriesList } from 'logic/redux/actions/categories';
import { fetchGroupsList } from 'logic/redux/actions/groups';
import { fetchProfile } from 'logic/redux/actions/user';
import { fetchOperators } from 'logic/redux/actions/operators';
import isAuthorized from 'helpers/authorization';
import LoadingScreen from 'components/LoadingScreen';

class DataProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preloaded: false
    };
  }

  async componentDidMount() {
    try {
      await this.props.fetchCategoriesList({ orderBy: 'name', orderDirection: 'asc' });
      await this.props.fetchGroupsList({ orderBy: 'name', orderDirection: 'asc' });
      await this.props.fetchProfile();

      if (isAuthorized(this.props.keycloak, ['app-admin'])) {
        await this.props.fetchOperators();
      }
    } catch (error) {
      // Stub
    } finally {
      this.setState({ preloaded: true });
    }
  }

  render() {
    const {
      state: { preloaded },
      props: { children }
    } = this;

    return preloaded ? children : <LoadingScreen />;
  }
}

DataProvider.propTypes = {
  children: PropTypes.node.isRequired,
  fetchCategoriesList: PropTypes.func.isRequired,
  fetchGroupsList: PropTypes.func.isRequired,
  fetchProfile: PropTypes.func.isRequired,
  fetchOperators: PropTypes.func.isRequired,
  keycloak: PropTypes.shape(KEYCLOAK_SHAPE).isRequired
};

const mapDispatchToProps = {
  fetchCategoriesList,
  fetchGroupsList,
  fetchProfile,
  fetchOperators
};

export default flow(withKeycloak, connect(null, mapDispatchToProps))(DataProvider);
