import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styles from './FallbackScreen.module.scss';

const FallbackScreen = ({ t }) => (
  <div className={styles.background}>
    <div className={styles.contentWrapper}>
      <span className={styles.message}>{t('generic_error_message')}</span>
    </div>
  </div>
);

FallbackScreen.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(FallbackScreen);
