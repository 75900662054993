import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import flow from 'lodash/flow';
import { withKeycloak } from '@react-keycloak/web';
import { withTranslation } from 'react-i18next';
import { fetchCalls, fetchCall, addCall, updateCall, removeCalls } from 'logic/redux/actions/calls';
import { fetchFilteredCompanies } from 'logic/redux/actions/companies';
import { fetchFilteredContacts } from 'logic/redux/actions/contacts';
import { fetchFilteredOperators } from 'logic/redux/actions/operators';
import { getCalls, getCount } from 'logic/redux/reducers/calls';
import { getGroupsList } from 'logic/redux/reducers/groups';
import { getCategoriesList } from 'logic/redux/reducers/categories';
import isAuthorized from 'helpers/authorization';
import { getProfile } from 'logic/redux/reducers/user';
import { KEYCLOAK_SHAPE, ITEM_DATA_TYPE, FIELD_TYPE, CALL_DIRECTION_TYPE } from 'config/constants';

import CrudView from 'components/CrudView';
import AutocompleteInput from 'components/AutocompleteInput';
import { splitNames } from 'helpers/names';

class Calls extends Component {
  constructor(props) {
    super(props);

    this.state = {
      operatorFilter: null
    };

    this.isAdmin = isAuthorized(props.keycloak, ['app-admin']);
    this.isOwner = isAuthorized(props.keycloak, ['app-owner']);
    this.itemFields = this.setItemFields();
    this.headerLabels = this.setHeaderLabels();
  }

  disableFormCheck = (itemInfo) => {
    if (itemInfo !== null) {
      const status = itemInfo.data.status.split('_')[0];

      return ['ringing', 'answered'].includes(status);
    }

    return false;
  };

  phoneInputDisableType = (itemInfo) => {
    if (itemInfo !== null) {
      const { contact } = itemInfo.data;

      return Boolean(contact);
    }

    return false;
  };

  displayProviderUser = (itemData) => {
    return this.isOwner || itemData.canChangeProviderUser;
  };

  displayDirection = (item) => {
    return !item.startTimestamp;
  };

  setHeaderLabels() {
    const { t } = this.props;

    const headerLabels = [
      {
        columnName: 'status',
        sortName: 'status',
        label: 'Status',
        type: ITEM_DATA_TYPE.callStatus
      },
      {
        columnName: 'createdAt',
        sortName: 'createdAt',
        label: t('generic_timestamp'),
        type: ITEM_DATA_TYPE.datetime
      },
      {
        columnName: 'from',
        sortName: 'originalFrom',
        label: 'From',
        type: ITEM_DATA_TYPE.phoneNumber
      },
      {
        columnName: 'to',
        sortName: 'originalTo',
        label: 'To',
        type: ITEM_DATA_TYPE.phoneNumber
      },
      {
        columnName: 'company',
        sortName: 'contact_company_name',
        label: 'Company',
        type: ITEM_DATA_TYPE.object
      },
      {
        columnName: 'contactGroup',
        sortName: 'contact_contactGroup_name',
        label: 'Group',
        type: ITEM_DATA_TYPE.object
      },
      {
        columnName: 'callCategory',
        sortName: 'callCategory_name',
        label: 'Category',
        type: ITEM_DATA_TYPE.object
      },
      {
        columnName: 'contactOwner',
        sortName: 'contact_contactOwner_name',
        label: 'Contact owner',
        type: ITEM_DATA_TYPE.object
      },
      {
        columnName: 'duration',
        sortName: 'duration',
        label: 'Duration',
        type: ITEM_DATA_TYPE.string
      }
    ];

    return headerLabels;
  }

  setItemFields() {
    const {
      categoriesList,
      groupsList,
      fetchFilteredCompanies,
      fetchFilteredContacts,
      fetchFilteredOperators,
      t,
      profile
    } = this.props;

    const FIELDS = {
      contact: {
        type: FIELD_TYPE.autocomplete,
        label: 'Contact',
        action: fetchFilteredContacts,
        addOption: true,
        delayExpand: true,
        updateAssociatedFields: ['company', 'contactGroup', 'jobDescription', 'firstName', 'lastName'],
        customNewValueObject: (value) => ({
          id: null,
          name: value,
          firstName: splitNames(value).firstName,
          lastName: splitNames(value).lastName
        })
      },
      firstName: {
        type: FIELD_TYPE.text,
        label: t('first_name_label'),
        parentFields: ['contact']
      },
      lastName: {
        type: FIELD_TYPE.text,
        label: t('last_name_label'),
        parentFields: ['contact']
      },
      company: {
        type: FIELD_TYPE.autocomplete,
        label: 'Company',
        action: fetchFilteredCompanies,
        addOption: true,
        parentFields: ['contact'],
        alwaysShow: true
      },
      jobDescription: {
        type: FIELD_TYPE.text,
        label: 'Job description',
        parentFields: ['contact']
      },
      contactGroup: {
        type: FIELD_TYPE.select,
        label: 'Group',
        options: groupsList,
        showNone: false,
        parentFields: ['contact', 'company']
      },
      callCategory: {
        type: FIELD_TYPE.select,
        label: 'Category',
        options: categoriesList,
        showNone: false
      },
      direction: {
        type: FIELD_TYPE.select,
        label: 'Direction',
        options: Object.keys(CALL_DIRECTION_TYPE).map((item) => ({
          id: item,
          name: CALL_DIRECTION_TYPE[item]
        })),
        showNone: false,
        shouldDisplay: this.displayDirection
      },
      duration: {
        type: FIELD_TYPE.time,
        label: 'Duration'
      },
      originalFrom: {
        type: FIELD_TYPE.phoneNumber,
        label: 'From',
        disableTypeCheck: this.phoneInputDisableType
      },
      originalTo: {
        type: FIELD_TYPE.phoneNumber,
        label: 'To',
        disableTypeCheck: this.phoneInputDisableType
      },
      contactOwner: {
        type: FIELD_TYPE.autocomplete,
        label: t('generic_contact_owner'),
        action: fetchFilteredOperators,
        addOption: false,
        defaultValue: {
          name: `${profile.firstName || ''} ${profile.lastName || ''}`,
          id: profile.id
        }
      },
      providerUser: {
        type: FIELD_TYPE.autocomplete,
        label: t('call_providerUser_label'),
        action: fetchFilteredOperators,
        addOption: false,
        shouldDisplay: this.displayProviderUser
      }
    };

    return FIELDS;
  }

  // TODO: Can be static
  getEmptyData() {
    return {
      originalFrom: { id: `new_${Date.now()}`, phoneNumber: '', type: '', city: '' },
      originalTo: { id: `new_${Date.now()}`, phoneNumber: '', type: '', city: '' },
      direction: null,
      contact: null,
      company: null,
      duration: '',
      jobDescription: '',
      providerUser: null,
      contactOwner: null,
      callCategory: null,
      contactGroup: null
    };
  }

  handleChange = async (event, valueObject) => {
    const { name, value } = valueObject;

    this.setState({ [name]: value });
  };

  get toolbarExtensions() {
    return {
      toolbarOptions: this.isAdmin ? (
        <AutocompleteInput
          addOption={false}
          label="Operator"
          fetchItems={this.props.fetchFilteredOperators}
          name="operatorFilter"
          value={this.state.operatorFilter}
          onChange={this.handleChange}
        />
      ) : null
    };
  }

  get extendedProps() {
    const { operatorFilter } = this.state;

    return {
      filterExtension:
        operatorFilter === null
          ? null
          : {
              providerUser: operatorFilter.name
            }
    };
  }

  render() {
    const {
      calls,
      count,
      fetchCalls,
      fetchCall,
      addCall,
      updateCall,
      removeCalls,
      match: {
        params: { id: callId }
      }
    } = this.props;
    return (
      <CrudView
        title="Calls"
        headerLabels={this.headerLabels}
        pageData={calls}
        count={count}
        getItems={fetchCalls}
        getItem={fetchCall}
        addItem={addCall}
        updateItem={updateCall}
        removeItems={removeCalls}
        emptyData={this.getEmptyData}
        fields={this.itemFields}
        preselectId={callId}
        toolbarExtensions={this.toolbarExtensions}
        extendedProps={this.extendedProps}
        disableFormCheck={this.disableFormCheck}
        extendedFilterColumns={['contact_firstName', 'contact_lastName', 'operator_sipUsername']}
      />
    );
  }
}

Calls.propTypes = {
  fetchCalls: PropTypes.func.isRequired,
  fetchCall: PropTypes.func.isRequired,
  addCall: PropTypes.func.isRequired,
  updateCall: PropTypes.func.isRequired,
  removeCalls: PropTypes.func.isRequired,
  fetchFilteredCompanies: PropTypes.func.isRequired,
  fetchFilteredContacts: PropTypes.func.isRequired,
  fetchFilteredOperators: PropTypes.func.isRequired,
  // TODO: Add call shape
  calls: PropTypes.arrayOf(PropTypes.object),
  count: PropTypes.number,
  keycloak: PropTypes.shape(KEYCLOAK_SHAPE).isRequired,
  t: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired
};

Calls.defaultProps = {
  calls: [],
  count: 0
};

const mapStateToProps = (state) => ({
  calls: getCalls(state),
  count: getCount(state),
  categoriesList: getCategoriesList(state),
  groupsList: getGroupsList(state),
  profile: getProfile(state)
});

const mapDispatchToProps = {
  fetchCalls,
  fetchCall,
  addCall,
  updateCall,
  removeCalls,
  fetchFilteredCompanies,
  fetchFilteredContacts,
  fetchFilteredOperators
};

export default flow(withTranslation(), withKeycloak, connect(mapStateToProps, mapDispatchToProps))(Calls);
