import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import styles from './StatusLabel.module.scss';

const StatusLabel = ({ active }) => (
  <Chip
    color={active ? 'primary' : 'secondary'}
    label={active ? 'Active' : 'Inactive'}
    classes={{
      colorPrimary: styles.active,
      colorSecondary: styles.inactive
    }}
  />
);

StatusLabel.propTypes = {
  active: PropTypes.bool
};

StatusLabel.defaultProps = {
  active: false
};

export default StatusLabel;
