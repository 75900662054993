import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { FormControl, OutlinedInput, InputLabel } from '@material-ui/core';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-2]/, /\d/, ':', /[0-5]/, /\d/, ':', /[0-5]/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

function TimeInput(props) {
  const { label, disabled, ...otherProps } = props;
  return (
    <FormControl variant="outlined" disabled={disabled} fullWidth margin="dense">
      <InputLabel htmlFor={`timeLabel_${label}`} shrink={true}>
        {label}
      </InputLabel>
      <OutlinedInput {...otherProps} label={label} id={`timeLabel_${label}`} inputComponent={TextMaskCustom} />
    </FormControl>
  );
}

TimeInput.propTypes = {
  label: PropTypes.string.isRequired
};

export default TimeInput;
