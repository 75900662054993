/* eslint-disable camelcase */
import moment from 'moment';

import { fetch } from 'logic/middleware/api';
import apiUrlByName from 'helpers/urls';
import { DATE_FORMAT_WITH_DAY_NAME } from 'config/constants';

// actions
export const SET_CALLS = 'call/set-calls';

// action creators
export const setCalls = (data, count) => ({
  type: SET_CALLS,
  data,
  count
});

function buildUpdateCall(body) {
  const {
    originalFrom,
    countryCode,
    originalTo,
    areaCode,
    number,
    duration,
    direction,
    jobDescription,
    contact,
    company,
    callCategory,
    contactGroup,
    providerUser,
    firstName,
    lastName,
    contactOwner
  } = body;

  const newBody = {
    areaCode,
    number,
    duration: moment.duration(duration).asSeconds(),
    jobDescription
  };

  if (!contact) {
    newBody.contactId = null;
  } else if (contact.id === null) {
    newBody.firstName = firstName;
    newBody.lastName = lastName;
  } else {
    newBody.contactId = contact.id;
  }

  if (!company) {
    newBody.companyId = null;
  } else if (company.id === null) {
    newBody.companyName = company.name;
  } else {
    newBody.companyId = company.id;
  }

  newBody.fromPhoneId = originalFrom.id && originalFrom.id.slice(0, 4) === 'new_' ? null : originalFrom.id;
  newBody.toPhoneId = originalTo.id && originalTo.id.slice(0, 4) === 'new_' ? null : originalTo.id;
  newBody.originalFrom = originalFrom.phoneNumber;
  newBody.originalTo = originalTo.phoneNumber;
  newBody.fromPhoneType = originalFrom.type;
  newBody.toPhoneType = originalTo.type;

  newBody.countryCode = countryCode ? countryCode.id : null;
  newBody.direction = direction ? direction.id : null;
  newBody.callCategoryId = callCategory ? callCategory.id : null;
  newBody.contactGroupId = contactGroup ? contactGroup.id : null;
  newBody.providerUser = providerUser ? providerUser.name : null;
  newBody.contactOwnerId = contactOwner ? contactOwner.id : null;

  return newBody;
}

function buildDisplayCall(body, countryCodes) {
  const {
    id,
    originalFrom,
    originalTo,
    fromPhoneType,
    toPhoneType,
    fromPhoneCity,
    toPhoneCity,
    fromPhoneId,
    toPhoneId,
    countryCode,
    areaCode,
    number,
    duration,
    direction,
    status,
    ringTimestamp,
    contactId,
    from,
    to,
    contact_firstName,
    contact_lastName,
    contact_jobDescription,
    companyId,
    contact_company_name,
    callCategoryId,
    callCategory_name,
    contact_contactGroupId,
    contact_contactGroup_name,
    providerUser,
    canChangeProviderUser,
    createdAt,
    contact_contactOwner_firstName,
    contact_contactOwner_lastName,
    contactOwnerId
  } = body;

  return {
    id,
    data: {
      originalFrom: {
        id: fromPhoneId,
        phoneNumber: originalFrom,
        type: fromPhoneType,
        city: fromPhoneCity
      },
      originalTo: {
        id: toPhoneId,
        phoneNumber: originalTo,
        type: toPhoneType,
        city: toPhoneCity
      },
      countryCode: getCountryCodeItem(countryCode, countryCodes),
      areaCode,
      number,
      from: {
        id: fromPhoneId,
        phoneNumber: from,
        type: fromPhoneType,
        city: fromPhoneCity,
        highlight: !contactId
      },
      to: {
        id: toPhoneId,
        phoneNumber: to,
        type: toPhoneType,
        city: toPhoneCity,
        highlight: !contactId
      },
      createdAt,
      canChangeProviderUser,
      duration: moment.utc(moment.duration(duration, 'seconds').asMilliseconds()).format('HH:mm:ss'),
      direction: { id: direction },
      status: `${status}_${direction}`,
      ringTimestamp,
      jobDescription: contact_jobDescription,
      firstName: contact_firstName,
      lastName: contact_lastName,
      contact: contactId
        ? {
            id: contactId,
            name: `${contact_firstName || ''} ${contact_lastName || ''}`
          }
        : null,
      company: companyId
        ? {
            id: companyId,
            name: contact_company_name
          }
        : null,
      callCategory: callCategoryId
        ? {
            id: callCategoryId,
            name: callCategory_name
          }
        : null,
      contactGroup: contact_contactGroupId
        ? {
            id: contact_contactGroupId,
            name: contact_contactGroup_name
          }
        : null,
      providerUser: providerUser
        ? {
            id: `id_${Date.now()}`,
            name: providerUser
          }
        : null,
      contactOwner: contactOwnerId
        ? {
            id: contactOwnerId,
            name: `${contact_contactOwner_firstName || ''} ${contact_contactOwner_lastName || ''}`
          }
        : null
    }
  };
}

export const fetchCalls = (queryParams = {}) => async (dispatch, getState) => {
  const { countryCodes } = getState().resources;
  const result = await dispatch(fetch(apiUrlByName('call', {}, queryParams), {}));
  const data = result.data.map((item) => buildDisplayCall(item, countryCodes));

  return dispatch(setCalls(groupByDate(data), result.totalCount));
};

export const fetchCall = (id) => async (dispatch, getState) => {
  const { countryCodes } = getState().resources;

  try {
    const result = await dispatch(fetch(apiUrlByName('callID', { id }), {}));

    return buildDisplayCall(result, countryCodes);
  } catch (error) {
    return null;
  }
};

export const addCall = (body) => (dispatch) => {
  const newBody = buildUpdateCall(body);

  return dispatch(
    fetch(apiUrlByName('call'), {
      method: 'post',
      body: JSON.stringify(newBody)
    })
  );
};

export const updateCall = (id, body) => (dispatch) => {
  const newBody = buildUpdateCall(body);

  return dispatch(
    fetch(apiUrlByName('callID', { id }), {
      method: 'put',
      body: JSON.stringify(newBody)
    })
  );
};

export const removeCalls = (ids) => (dispatch) =>
  dispatch(
    fetch(apiUrlByName('call', {}, { ids: ids.join(',') }), {
      method: 'delete'
    })
  );

function getCountryCodeItem(code, countries = []) {
  const countryCodeItem = countries.find((item) => item.id === code);

  return countryCodeItem || null;
}

function groupByDate(data) {
  const today = moment();
  const yesterday = today.clone().subtract(1, 'days').startOf('day');
  const twoDaysAgo = today.clone().subtract(2, 'days').startOf('day');
  const threeDaysAgo = today.clone().subtract(2, 'days').startOf('day');

  let groupLabel = '';

  const newPageData = data.map((item) => {
    const momentCreatedAt = moment(item.data.createdAt);
    let label = null;

    if (momentCreatedAt.isSame(today, 'd')) {
      label = `Today`;
    } else if (momentCreatedAt.isSame(yesterday, 'd')) {
      label = `Yesterday`;
    } else if (momentCreatedAt.isSame(twoDaysAgo, 'd') || momentCreatedAt.isSame(threeDaysAgo, 'd')) {
      label = momentCreatedAt.format('dddd');
    } else label = moment(item.data.createdAt).format(DATE_FORMAT_WITH_DAY_NAME);

    if (label === groupLabel) return item;

    groupLabel = label;

    return {
      ...item,
      label: groupLabel
    };
  });

  return newPageData;
}
