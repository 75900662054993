import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withKeycloak } from '@react-keycloak/web';

import { Paper, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@material-ui/core';

import ContentView from 'components/ContentView';
import SidePanel from 'sections/Dashboard/SidePanel';
import { fetchDashboard } from 'logic/redux/actions/dashboard';
import { fetchOperators } from 'logic/redux/actions/operators';
import { getDashboard } from 'logic/redux/reducers/dashboard';
import { getOperators } from 'logic/redux/reducers/operators';
import isAuthorized from 'helpers/authorization';

import styles from './Dashboard.module.scss';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentItem: null
    };

    this.isAdmin = isAuthorized(props.keycloak, ['app-admin']);
  }

  componentDidMount() {
    const { fetchDashboard, fetchOperators } = this.props;

    if (this.isAdmin) {
      fetchOperators();
    } else {
      fetchDashboard();
    }
  }

  selectOperator = (id) => {
    this.setState({ currentItem: id }, () => {
      this.props.fetchDashboard(id);
    });
  };

  getCurrentItemName = () => {
    if (this.isAdmin) {
      const itemInfo = this.props.operators.find((item) => item.id === this.state.currentItem);

      return typeof itemInfo === 'undefined' ? null : itemInfo.data.sipUsername;
    }

    return this.props.keycloak.tokenParsed.preferred_username;
  };

  render() {
    const {
      state: { currentItem },
      props: { operators, dashboardData }
    } = this;

    const operatorName = this.getCurrentItemName();

    return (
      <ContentView title="Dashboard">
        {this.isAdmin && <SidePanel items={operators} currentItem={currentItem} onSelectItem={this.selectOperator} />}

        <Paper className={styles.paper}>
          {operatorName ? (
            <>
              <Typography variant="h5" align="center">
                {operatorName}
              </Typography>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Category</b>
                    </TableCell>
                    <TableCell>
                      <b>Duration</b>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {dashboardData.map(({ callCategoryId, callCategory_name, totalSpent }) => (
                    <TableRow key={callCategoryId}>
                      <TableCell>{callCategory_name || <i>- Uncategorized -</i>}</TableCell>
                      <TableCell>{totalSpent}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          ) : (
            <Typography variant="h5" align="center">
              Select an "Operator" to view its data
            </Typography>
          )}
        </Paper>
      </ContentView>
    );
  }
}

Dashboard.propTypes = {
  fetchDashboard: PropTypes.func.isRequired,
  fetchOperators: PropTypes.func.isRequired,
  dashboardData: PropTypes.array,
  operators: PropTypes.array
};

Dashboard.defaultProps = {
  dashboardData: [],
  operators: []
};

const mapStateToProps = (state) => ({
  dashboardData: getDashboard(state),
  operators: getOperators(state)
});

const mapDispatchToProps = {
  fetchDashboard,
  fetchOperators
};

export default withKeycloak(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
