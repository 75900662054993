import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { parsePhoneNumberFromString, isPossibleNumber } from 'libphonenumber-js';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { sentenceCase } from 'helpers/transformers';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import CustomPhoneType from 'components/CustomPhoneType';
import styles from './PhoneDisplay.module.scss';

function getFormattedPhoneNumber(value) {
  if (isPossibleNumber(`+${value}`)) {
    return parsePhoneNumberFromString(`+${value}`).formatInternational();
  }
  if (value === 'anonymous' || value === 'voicemail') {
    return sentenceCase(value);
  }
  return value;
}

function displayPhoneItem({ phoneNumber, type, city, highlight }) {
  return (
    <div className={classNames(styles.phoneItem, { [styles.highlight]: highlight })} key={phoneNumber}>
      <CustomPhoneType type={type} title={city} />
      {getFormattedPhoneNumber(phoneNumber)}
    </div>
  );
}

function PhoneDisplay({ phoneNumbers }) {
  if (!Array.isArray(phoneNumbers)) {
    return displayPhoneItem(phoneNumbers);
  }

  switch (phoneNumbers.length) {
    case 0:
      return '-';
    case 1:
      return displayPhoneItem(phoneNumbers[0]);
    default:
      const summary = phoneNumbers.slice(0, 1)[0];
      const details = phoneNumbers.slice(1, phoneNumbers.length);

      return (
        <Accordion
          square
          elevation={0}
          classes={{ root: styles.accordionRoot }}
          onClick={(event) => event.stopPropagation()}
        >
          <AccordionSummary
            classes={{
              root: styles.accordionSummaryRoot,
              content: styles.accordionSummaryContent,
              expanded: styles.accordionSummaryExpanded,
              expandIcon: styles.accordionSummaryExpandIcon
            }}
            expandIcon={<ExpandMoreIcon fontSize="small" />}
            IconButtonProps={{
              edge: false
            }}
          >
            {displayPhoneItem(summary)}
          </AccordionSummary>
          <AccordionDetails classes={{ root: styles.accordionDetailsRoot }}>
            {details.map(displayPhoneItem)}
          </AccordionDetails>
        </Accordion>
      );
  }
}

PhoneDisplay.propTypes = {
  phoneNumbers: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))
  ]).isRequired
};

export default PhoneDisplay;
