import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Paper } from '@material-ui/core';

import styles from './PanelView.module.scss';

const PanelView = ({ children, float, overflowY }) => (
  <Paper
    className={classNames(styles.paper, {
      [styles[`${float}Float`]]: float,
      [styles[`${overflowY}OverflowY`]]: overflowY
    })}
  >
    {children}
  </Paper>
);

PanelView.defaultProps = {
  float: 'right',
  overflowY: 'auto'
};

PanelView.propTypes = {
  children: PropTypes.node.isRequired,
  float: PropTypes.oneOf(['left', 'right']),
  overflowY: PropTypes.oneOf(['auto', 'scroll', 'hidden'])
};

export default PanelView;
