import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Typography, TextField, InputAdornment, List, ListItem, ListItemText } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';

import PanelView from 'components/PanelView';

import styles from './SidePanel.module.scss';

class SidePanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: ''
    };
  }

  get itemList() {
    const {
      state: { search },
      props: { items }
    } = this;

    if (search) {
      return items.filter((item) => item.data.sipUsername.toLowerCase().includes(search.toLowerCase()));
    }

    return items;
  }

  selectItem = (event) => {
    this.props.onSelectItem(event.currentTarget.dataset.value);
  };

  searchChange = (event) => {
    this.setState({ search: event.target.value });
  };

  render() {
    const { currentItem } = this.props;

    return (
      <PanelView float="left" overflowY="hidden">
        <Typography variant="h5" align="center">
          Operators
        </Typography>

        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          placeholder="Search..."
          value={this.state.search}
          onChange={this.searchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            )
          }}
        />

        <List className={styles.userList} component="div">
          {this.itemList.map(({ id, data: { sipUsername } }) => (
            <ListItem key={id} button divider data-value={id} selected={id === currentItem} onClick={this.selectItem}>
              <ListItemText>{sipUsername}</ListItemText>
            </ListItem>
          ))}
        </List>
      </PanelView>
    );
  }
}

SidePanel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelectItem: PropTypes.func.isRequired,
  currentItem: PropTypes.string
};

export default SidePanel;
