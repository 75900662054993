import { SET_COUNTRY_CODES } from 'logic/redux/actions/resources';

const initialState = {
  countryCodes: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_COUNTRY_CODES:
      return {
        ...state,
        countryCodes: action.countryCodes
      };
    default:
      return state;
  }
}

const getStateBase = (state) => state.resources;

export const getCountryCodes = (state) => getStateBase(state).countryCodes;
