import { fetch } from 'logic/middleware/api';
import apiUrlByName from 'helpers/urls';

// actions
export const SET_COMPANIES = 'company/set-companies';

// action creators
export const setCompanies = (data, count) => ({
  type: SET_COMPANIES,
  data,
  count
});

function buildDisplayItem(body) {
  const { id, name, website } = body;

  return { id, data: { name, website } };
}

export const fetchCompanies = (queryParams = {}) => async (dispatch) => {
  const result = await dispatch(fetch(apiUrlByName('company', {}, queryParams), {}));

  const data = result.data.map((item) => buildDisplayItem(item));

  return dispatch(setCompanies(data, result.totalCount));
};

export const fetchCompany = (id) => async (dispatch) => {
  try {
    const result = await dispatch(fetch(apiUrlByName('companyID', { id }), {}));

    return buildDisplayItem(result);
  } catch (error) {
    return null;
  }
};

export const fetchFilteredCompanies = (filter) => async (dispatch) => {
  const result = await dispatch(
    fetch(apiUrlByName('company', {}, { limit: 8, filter_name: filter, orderBy: 'name', orderDirection: 'asc' }), {})
  );

  return result.data;
};

export const addCompany = (body) => (dispatch) =>
  dispatch(
    fetch(apiUrlByName('company'), {
      method: 'post',
      body: JSON.stringify(body)
    })
  );

export const updateCompany = (id, body) => (dispatch) =>
  dispatch(
    fetch(apiUrlByName('companyID', { id }), {
      method: 'put',
      body: JSON.stringify(body)
    })
  );

export const removeCompanies = (ids) => (dispatch) =>
  dispatch(
    fetch(apiUrlByName('company', {}, { ids: ids.join(',') }), {
      method: 'delete'
    })
  );
