import React from 'react';
import PropTypes from 'prop-types';

import { Drawer } from '@material-ui/core';

import styles from './DrawerView.module.scss';

function DrawerView({ open, children }) {
  return (
    <Drawer open={open} variant="persistent" anchor="right" PaperProps={{ className: styles.drawer }}>
      {children}
    </Drawer>
  );
}

DrawerView.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

export default DrawerView;
