import { fetch } from 'logic/middleware/api';
import apiUrlByName from 'helpers/urls';

// actions
export const SET_OPERATORS = 'operators/set-operators';

// action creators
export const setOperators = (data, count) => ({
  type: SET_OPERATORS,
  data,
  count
});

function buildDisplayOperator(body) {
  const { id, sipUsername, username, lastName, firstName, email } = body;

  return { id, data: { sipUsername, username, email, lastName, firstName } };
}

export const fetchOperators = (queryParams = {}) => async (dispatch) => {
  const result = await dispatch(fetch(apiUrlByName('user', {}, queryParams), {}));

  const data = result.data.map((item) => buildDisplayOperator(item));

  return dispatch(setOperators(data, result.totalCount));
};

export const fetchOperator = (id) => async (dispatch) => {
  try {
    const result = await dispatch(fetch(apiUrlByName('userID', { id }), {}));

    return buildDisplayOperator(result);
  } catch (error) {
    return null;
  }
};

export const fetchFilteredOperators = (filter) => async (dispatch) => {
  const result = await dispatch(
    fetch(
      apiUrlByName(
        'user',
        {},
        {
          limit: 10,
          filter_username: filter,
          filter_sipUsername: filter,
          orderBy: 'lastName',
          orderDirection: 'asc'
        }
      ),
      {}
    )
  );

  return result.data.map(({ id, sipUsername }) => ({
    id,
    name: sipUsername
  }));
};

export const updateOperator = (id, body) => (dispatch) =>
  dispatch(
    fetch(apiUrlByName('userID', { id }), {
      method: 'put',
      body: JSON.stringify(body)
    })
  );
