import moment from 'moment';

import { fetch } from 'logic/middleware/api';
import apiUrlByName from 'helpers/urls';

// actions
export const DASHBOARD_LOADED = 'dashboard/loaded';

// action creators
export const dashboardLoaded = (data) => ({
  type: DASHBOARD_LOADED,
  data
});

export const fetchDashboard = (id) => async (dispatch) => {
  let data;

  if (id) {
    data = await dispatch(fetch(apiUrlByName('dashboardID', { id }), {}));
  } else {
    data = await dispatch(fetch(apiUrlByName('dashboard'), {}));
  }

  data = data.map(({ totalSpent, ...otherProps }) => {
    const duration = moment.duration(totalSpent, 'seconds');

    return {
      ...otherProps,
      totalSpent: `${parseInt(duration.asHours(), 10)}:${moment.utc(duration.asMilliseconds()).format('mm:ss')}`
    };
  });

  return dispatch(dashboardLoaded(data));
};
