import React from 'react';
import PropTypes from 'prop-types';
import { withKeycloak } from '@react-keycloak/web';
import { Redirect, Route } from 'react-router-dom';
import { KEYCLOAK_SHAPE } from 'config/constants';
import isAuthorized from 'helpers/authorization';

const ProtectedRoute = ({ component: Component, roles, keycloak, ...rest }) => (
  <Route
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    render={(props) => {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return isAuthorized(keycloak, roles) ? <Component {...props} /> : <Redirect to={{ pathname: '/' }} />;
    }}
  />
);

ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  keycloak: PropTypes.shape(KEYCLOAK_SHAPE).isRequired
};

export default withKeycloak(ProtectedRoute);
