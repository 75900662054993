import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Divider, Typography, LinearProgress } from '@material-ui/core';

import styles from './ContentView.module.scss';

function ContentView({ children, title, loading, toolbarPrimary, toolbarSecondary, toolbarOptions }) {
  return (
    <>
      <AppBar className={styles.appBar} color="inherit" elevation={0}>
        <Toolbar className={styles.toolbar}>
          <Typography className={styles.title} variant="h5">
            {title}
          </Typography>

          <div className={styles.toolbarGrow}>{toolbarPrimary}</div>
          <div className={styles.toolbarGrow}>{toolbarSecondary}</div>
          <div className={styles.toolbarGrow}>{toolbarOptions}</div>
        </Toolbar>

        <Divider />
      </AppBar>

      {loading ? <LinearProgress /> : children}
    </>
  );
}

ContentView.defaultProps = {
  loading: false,
  toolbarPrimary: null,
  toolbarSecondary: null,
  toolbarOptions: null
};

ContentView.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  toolbarPrimary: PropTypes.node,
  toolbarSecondary: PropTypes.node,
  toolbarOptions: PropTypes.node
};

export default ContentView;
