import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  Paper,
  TableSortLabel,
  Checkbox,
  Typography
} from '@material-ui/core';
import { TIME_FORMAT, ITEM_DATA_TYPE } from 'config/constants';
import CustomCallStatus from 'components/CustomCallStatus';

import PhoneDisplay from 'components/PhoneDisplay';
import styles from './CustomTable.module.scss';

class CustomTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollTop: 0
    };
    this.tableRef = createRef();
    this.labelColSpan = this.props.headerLabels.length + 1;
  }

  componentDidMount() {
    const tableRef = this.tableRef.current;
    tableRef.scrollTop = sessionStorage.getItem('scrollTop') || 0;
  }

  componentWillUnmount() {
    const tableRef = this.tableRef.current;
    sessionStorage.setItem('scrollTop', tableRef.scrollTop);
  }

  isSelectedAll() {
    const { selectedItems, pageData } = this.props;

    return selectedItems.length > 0 && selectedItems.length === pageData.length;
  }

  isSelectedPartially() {
    const { selectedItems, pageData } = this.props;

    return selectedItems.length > 0 && selectedItems.length < pageData.length;
  }

  isSelected(id) {
    const { selectedItems } = this.props;

    return selectedItems.indexOf(id) !== -1;
  }

  isCurrentItem(id) {
    const { currentItem } = this.props;

    return id === currentItem;
  }

  render() {
    const {
      headerLabels,
      orderBy,
      orderDirection,
      hideCheckBox,
      count,
      page,
      pageData,
      rowsPerPage,
      onChangePage,
      onChangeRowsPerPage,
      onSelectAllRows,
      onRequestSort,
      onSelectCurrentItem,
      onSelectRow
    } = this.props;
    return (
      <Paper className={styles.paper}>
        <TableContainer className={styles.tableContainer} ref={this.tableRef}>
          <Table size="medium" stickyHeader>
            <TableHead>
              <TableRow>
                {hideCheckBox ? null : (
                  <TableCell padding="checkbox" onClick={onSelectAllRows}>
                    <Checkbox
                      color="primary"
                      indeterminate={this.isSelectedPartially()}
                      checked={this.isSelectedAll()}
                    />
                  </TableCell>
                )}
                {headerLabels.map(({ sortName, label }) => (
                  <TableCell key={`th_${sortName}`}>
                    <TableSortLabel
                      active={orderBy === sortName}
                      direction={orderBy === sortName ? orderDirection : 'asc'}
                      data-value={sortName}
                      onClick={onRequestSort}
                    >
                      {label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody className={styles.tableBody}>
              {pageData.map(({ id, data, label }) => {
                return (
                  <Fragment key={`tr_${id}`}>
                    {label && (
                      <TableRow>
                        <TableCell className={styles.labelCell} colSpan={this.labelColSpan}>
                          <Typography variant="caption" classes={{ root: styles.labelCellSpan }}>
                            {label}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}

                    <TableRow hover data-value={id} selected={this.isCurrentItem(id)} onClick={onSelectCurrentItem}>
                      {hideCheckBox ? null : (
                        <TableCell padding="checkbox" data-value={id} onClick={onSelectRow}>
                          <Checkbox color="primary" checked={this.isSelected(id)} />
                        </TableCell>
                      )}
                      {headerLabels.map(({ columnName, type }) => {
                        let value;
                        if (data[columnName]) {
                          switch (type) {
                            case ITEM_DATA_TYPE.object:
                              value = data[columnName].name;
                              break;
                            case ITEM_DATA_TYPE.phoneNumber:
                              value = <PhoneDisplay phoneNumbers={data[columnName]} />;
                              break;
                            case ITEM_DATA_TYPE.callStatus:
                              value = (
                                <CustomCallStatus
                                  type={data[columnName]}
                                  canChangeProviderUser={data.canChangeProviderUser}
                                />
                              );
                              break;
                            case ITEM_DATA_TYPE.date:
                            case ITEM_DATA_TYPE.datetime:
                              value = moment(data[columnName]).format(TIME_FORMAT);
                              break;
                            default:
                              value = data[columnName];
                              break;
                          }
                        } else {
                          value = '-';
                        }

                        return <TableCell key={`tr_${id}_${columnName}`}>{value}</TableCell>;
                      })}
                    </TableRow>
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

CustomTable.defaultProps = {
  currentItem: null,
  pageData: [],
  hideCheckBox: false
};

CustomTable.propTypes = {
  headerLabels: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])))
    .isRequired,
  orderBy: PropTypes.string.isRequired,
  orderDirection: PropTypes.string.isRequired,
  hideCheckBox: PropTypes.bool,
  onRequestSort: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  pageData: PropTypes.arrayOf(PropTypes.object),
  rowsPerPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onSelectRow: PropTypes.func.isRequired,
  onSelectAllRows: PropTypes.func.isRequired,
  onSelectCurrentItem: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  currentItem: PropTypes.string
};

export default CustomTable;
