import { DASHBOARD_LOADED } from 'logic/redux/actions/dashboard';

const initialState = {
  data: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DASHBOARD_LOADED:
      return {
        ...state,
        data: action.data
      };
    default:
      return state;
  }
}

const getStateBase = (state) => state.dashboard;

export const getDashboard = (state) => getStateBase(state).data;
