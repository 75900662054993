import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import flow from 'lodash/flow';
import { withTranslation } from 'react-i18next';
import {
  fetchContacts,
  fetchContact,
  addContact,
  updateContact,
  removeContacts,
  deletePersonalInformation
} from 'logic/redux/actions/contacts';
import { getContacts, getCount } from 'logic/redux/reducers/contacts';
import { fetchFilteredCompanies } from 'logic/redux/actions/companies';
import { fetchFilteredOperators } from 'logic/redux/actions/operators';
import { getGroupsList } from 'logic/redux/reducers/groups';
import { ITEM_DATA_TYPE, FIELD_TYPE } from 'config/constants';
import CrudView from 'components/CrudView';

function emptyData() {
  return {
    firstName: '',
    lastName: '',
    phoneNumbers: [],
    jobDescription: '',
    company: null,
    contactGroup: null,
    contactOwner: null
  };
}

function Contacts(props) {
  const {
    contacts,
    count,
    groupsList,
    fetchContacts,
    fetchContact,
    addContact,
    updateContact,
    removeContacts,
    fetchFilteredCompanies,
    deletePersonalInformation,
    fetchFilteredOperators
  } = props;

  const HEADER_LABELS = [
    { columnName: 'lastName', sortName: 'lastName', label: 'Last Name', type: ITEM_DATA_TYPE.string },
    { columnName: 'firstName', sortName: 'firstName', label: 'First Name', type: ITEM_DATA_TYPE.string },
    {
      columnName: 'phoneNumbers',
      sortName: 'phoneNumbers_phoneNumber',
      label: 'Phone Numbers',
      type: ITEM_DATA_TYPE.phoneNumber
    },
    { columnName: 'jobDescription', sortName: 'jobDescription', label: 'Job description', type: ITEM_DATA_TYPE.string },
    { columnName: 'email', sortName: 'email', label: 'Email', type: ITEM_DATA_TYPE.string },
    { columnName: 'company', sortName: 'company_name', label: 'Company', type: ITEM_DATA_TYPE.object },
    { columnName: 'contactGroup', sortName: 'contactGroup_name', label: 'Group', type: ITEM_DATA_TYPE.object },
    {
      columnName: 'contactOwner',
      sortName: 'contactOwner_name',
      label: props.t('contact_owner'),
      type: ITEM_DATA_TYPE.object
    }
  ];

  const FIELDS = {
    firstName: {
      type: FIELD_TYPE.text,
      label: 'First name'
    },
    lastName: {
      type: FIELD_TYPE.text,
      label: 'Last name'
    },
    phoneNumbers: {
      type: FIELD_TYPE.multiPhoneNumber,
      label: 'Phone Numbers'
    },
    jobDescription: {
      type: FIELD_TYPE.text,
      label: 'Job description'
    },
    email: {
      type: FIELD_TYPE.text,
      label: 'Email'
    },
    company: {
      type: FIELD_TYPE.autocomplete,
      label: 'Company',
      action: fetchFilteredCompanies,
      addOption: true
    },
    contactGroup: {
      type: FIELD_TYPE.select,
      label: 'Group',
      options: groupsList,
      showNone: false
    },
    contactOwner: {
      type: FIELD_TYPE.autocomplete,
      label: props.t('contact_owner'),
      action: fetchFilteredOperators,
      addOption: false
    }
  };

  return (
    <CrudView
      title="Contacts"
      headerLabels={HEADER_LABELS}
      pageData={contacts}
      count={count}
      getItems={fetchContacts}
      getItem={fetchContact}
      addItem={addContact}
      updateItem={updateContact}
      removeItems={removeContacts}
      deletePersonalInformation={deletePersonalInformation}
      emptyData={emptyData}
      fields={FIELDS}
      defaultOrderBy="lastName"
      defaultOrderDirection="asc"
    />
  );
}

Contacts.propTypes = {
  fetchContacts: PropTypes.func.isRequired,
  fetchContact: PropTypes.func.isRequired,
  addContact: PropTypes.func.isRequired,
  updateContact: PropTypes.func.isRequired,
  removeContacts: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  contacts: PropTypes.array,
  deletePersonalInformation: PropTypes.func.isRequired,
  fetchFilteredOperators: PropTypes.func.isRequired,
  count: PropTypes.number
};

Contacts.defaultProps = {
  contacts: [],
  count: 0
};

const mapStateToProps = (state) => ({
  contacts: getContacts(state),
  count: getCount(state),
  groupsList: getGroupsList(state)
});

const mapDispatchToProps = {
  fetchContacts,
  fetchContact,
  addContact,
  updateContact,
  removeContacts,
  fetchFilteredCompanies,
  fetchFilteredOperators,
  deletePersonalInformation
};

export default flow(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Contacts);
