import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import flow from 'lodash/flow';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { HISTORY_SHAPE } from 'config/constants';
import { getSocketClient } from 'helpers/socket';
import { notify } from 'helpers/notification';
import Notification from 'logic/Notification';
import { getProfile } from 'logic/redux/reducers/user';
import { fetchCalls } from 'logic/redux/actions/calls';
import { setRealtimeStatus } from 'logic/redux/actions/settings';

class Socketer extends Component {
  componentDidMount() {
    const { t, fetchCalls } = this.props;
    this.socket = getSocketClient(this.props.sipUsername);
    this.socket.on('connect', () => this.props.setRealtimeStatus(true));
    this.socket.on('phonecall', (payload) => {
      const message = {
        body: '',
        title: ''
      };
      fetchCalls({ limit: 50, offset: 0, orderBy: 'createdAt', order: 'desc' });
      const parsedPayload = JSON.parse(payload);
      switch (parsedPayload.type) {
        case 'ring':
          message.title = t('incoming_call_from', { contact: parsedPayload.contact });
          message.body = parsedPayload.phoneNumber;
          Notification.callRing(parsedPayload.contact, parsedPayload.phoneNumber, () => {
            this.props.history.replace(`/calls/${parsedPayload.callId}`);
          });
          notify(message);
          break;
        case 'answer':
          break;
        case 'hangup':
          message.title = t('hangup_call_from', { contact: parsedPayload.contact });
          message.body = parsedPayload.phoneNumber;
          Notification.callEnded(parsedPayload.contact, parsedPayload.phoneNumber, () => {
            this.props.history.replace(`/calls/${parsedPayload.callId}`);
          });
          notify(message);
          break;
        default:
          console.error('Unknown notification type');
      }
    });
  }

  render() {
    return <>{this.props.children}</>;
  }
}

Socketer.propTypes = {
  children: PropTypes.node.isRequired,
  sipUsername: PropTypes.string.isRequired,
  history: PropTypes.shape(HISTORY_SHAPE).isRequired,
  setRealtimeStatus: PropTypes.func.isRequired,
  fetchCalls: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  sipUsername: getProfile(state).sipUsername
});

const mapDispatchToProps = {
  setRealtimeStatus,
  fetchCalls
};

export default flow(withTranslation(), withRouter, connect(mapStateToProps, mapDispatchToProps))(Socketer);
