import React from 'react';
import PropTypes from 'prop-types';
import { withKeycloak } from '@react-keycloak/web';
import { KEYCLOAK_SHAPE } from 'config/constants';
import isAuthorized from 'helpers/authorization';

const RoleWrapper = ({ roles, keycloak, children }) => {
  return <>{isAuthorized(keycloak, roles) ? children : null}</>;
};

RoleWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  keycloak: PropTypes.shape(KEYCLOAK_SHAPE).isRequired
};

export default withKeycloak(RoleWrapper);
