import GERMAN_PHONE_NUMBER_PREFIXES from 'config/germanPhoneNumberPrefixes.json';
import { EMPTY_PARSED_PHONE_NUMBER } from 'config/constants';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const isGermanMobilePhoneNumber = (nationalNumber) => {
  let isMobilePhoneNumber = false;

  if (!nationalNumber) {
    return isMobilePhoneNumber;
  }

  GERMAN_PHONE_NUMBER_PREFIXES.forEach((prefix) => {
    if (nationalNumber.startsWith(prefix)) {
      isMobilePhoneNumber = true;
    }
  });

  return isMobilePhoneNumber;
};

const parsePhoneNumber = (phoneNumber) => {
  try {
    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber[0] === '+' ? phoneNumber : `+${phoneNumber}`);
    if (parsedPhoneNumber) {
      return parsedPhoneNumber;
    }
    return EMPTY_PARSED_PHONE_NUMBER;
  } catch (e) {
    return EMPTY_PARSED_PHONE_NUMBER;
  }
};

export const getNationalNumber = (phoneNumber) => {
  return parsePhoneNumber(phoneNumber).nationalNumber;
};

export const getPhoneNumberType = (nationalNumber, type) => {
  if (type) {
    return type;
  }
  if (isGermanMobilePhoneNumber(getNationalNumber(nationalNumber))) {
    return 'mobile';
  }
  return 'office';
};
