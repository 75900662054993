import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PhoneInput from 'components/PhoneInput';
import { IconButton, Typography } from '@material-ui/core';
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons';

class PhoneMultiInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (event, { name: id, value }) => {
    const { onChange, name, value: oldValue } = this.props;

    const newValue = oldValue.map((item) => (item.id === id ? value : item));

    onChange(null, { name, value: newValue });
  };

  addPhoneNumber = () => {
    const { onChange, name, value: newValue } = this.props;

    newValue.push(this.emptyField);

    onChange(null, { name, value: newValue });
  };

  removePhoneNumber = (event) => {
    const { onChange, name, value } = this.props;
    const id = event.currentTarget.dataset.value;

    let newValue = value.filter((item) => item.id !== id);

    if (newValue.length === 0) {
      newValue = [this.emptyField];
    }

    onChange(null, { name, value: newValue });
  };

  get emptyField() {
    return { id: `new_${Date.now()}`, phoneNumber: '', type: '' };
  }

  get phoneFields() {
    const { value, disabled } = this.props;

    return value.map((item) => (
      <div key={`phoneInput-${item.id}`} style={{ display: 'flex' }}>
        <PhoneInput value={item} name={item.id} onChange={this.handleChange} flexGrow disabled={disabled} />

        {disabled || (
          <IconButton
            color="secondary"
            size="small"
            style={{ margin: '15px 0 12px 4px' }}
            data-value={item.id}
            onClick={this.removePhoneNumber}
          >
            <RemoveIcon />
          </IconButton>
        )}
      </div>
    ));
  }

  render() {
    const { label, disabled } = this.props;

    return (
      <>
        <div style={{ display: 'flex' }}>
          <Typography variant="body2" color="textSecondary" style={{ margin: '6px 12px', flexGrow: 1 }}>
            {label}
          </Typography>
          <IconButton color="primary" size="small" onClick={this.addPhoneNumber} disabled={disabled}>
            <AddIcon />
          </IconButton>
        </div>

        {this.phoneFields}
      </>
    );
  }
}

PhoneMultiInput.defaultProps = {
  disabled: false
};

PhoneMultiInput.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default PhoneMultiInput;
