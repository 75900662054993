import React from 'react';
import PropTypes from 'prop-types';
import { CallMissed, CallMissedOutgoing, CallReceived, CallMade, Flag } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

import { CALL_STATUS_TYPE } from 'config/constants';
import PulsingCircle from 'components/Animations/PulsingCircle';
import styles from './CustomCallStatus.module.scss';

function CustomCallStatus({ type, canChangeProviderUser }) {
  if (type === '') {
    return '-';
  }

  const providerUserFlag = canChangeProviderUser ? (
    <Tooltip title="Change Assigned Operator">
      <Flag color="error" />
    </Tooltip>
  ) : null;

  const [status, direction] = type.split('_');

  if (direction === 'in') {
    switch (status) {
      case 'ringing':
      case 'answered':
        return (
          <>
            <PulsingCircle />
            {providerUserFlag}
          </>
        );

      case 'missed':
        return (
          <>
            <Tooltip title={`${CALL_STATUS_TYPE[status]} Incoming`}>
              <CallMissed className={styles.missedIcon} />
            </Tooltip>
            {providerUserFlag}
          </>
        );

      case 'completed':
        return (
          <>
            <Tooltip title={`${CALL_STATUS_TYPE[status]} Incoming`}>
              <CallReceived className={styles.callInCompletedIcon} />
            </Tooltip>
            {providerUserFlag}
          </>
        );

      default:
        return (
          <>
            <Tooltip title="Incoming">
              <CallReceived className={styles.callOutIcon} />
            </Tooltip>
            {providerUserFlag}
          </>
        );
    }
  } else if (direction === 'out') {
    switch (status) {
      case 'ringing':
      case 'answered':
        return (
          <>
            <PulsingCircle />
            {providerUserFlag}
          </>
        );

      case 'missed':
        return (
          <>
            <Tooltip title={`${CALL_STATUS_TYPE[status]} Outgoing`}>
              <CallMissedOutgoing className={styles.missedIcon} />
            </Tooltip>
            {providerUserFlag}
          </>
        );

      case 'completed':
        return (
          <>
            <Tooltip title={`${CALL_STATUS_TYPE[status]} Outgoing`}>
              <CallMade className={styles.callOutCompletedIcon} />
            </Tooltip>
            {providerUserFlag}
          </>
        );

      default:
        return (
          <>
            <Tooltip title="Outgoing">
              <CallMade className={styles.callOutIcon} />
            </Tooltip>
            {providerUserFlag}
          </>
        );
    }
  } else {
    return '-';
  }
}

CustomCallStatus.defaultProps = {
  type: ''
};

CustomCallStatus.propTypes = {
  type: PropTypes.string
};

export default CustomCallStatus;
