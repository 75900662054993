import { SET_CONTACTS } from 'logic/redux/actions/contacts';

const initialState = {
  items: [],
  count: 0
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CONTACTS:
      return {
        ...state,
        items: action.data,
        count: action.count
      };
    default:
      return state;
  }
}

const getStateBase = (state) => state.contacts;

export const getContacts = (state) => getStateBase(state).items;
export const getCount = (state) => getStateBase(state).count;
