import React from 'react';
import { Drawer, MenuList, Divider, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  Dashboard as DashboardIcon,
  Contacts as ContactsIcon,
  Phone as CallsIcon,
  Business as CompaniesIcon,
  Tune as AttributesIcon,
  Settings as SettingsIcon
} from '@material-ui/icons/';
import { withTranslation } from 'react-i18next';
import NavigationItem from 'components/NavigationItem';
import NavigationExpandableItem from 'components/NavigationExpandableItem';
import RoleWrapper from 'components/RoleWrapper';
import styles from './Navigation.module.scss';

const Navigation = ({ t }) => {
  return (
    <Drawer className={styles.drawer} variant="permanent" classes={{ paper: styles.drawerPaper }}>
      <MenuList className={styles.drawerContent}>
        <NavigationItem title="Dashboard" link="/" icon={<DashboardIcon />} />

        <Divider />

        <NavigationItem title="Calls" link="/calls" icon={<CallsIcon />} />
        <NavigationItem title="Contacts" link="/contacts" icon={<ContactsIcon />} />
        <NavigationItem title="Companies" link="/companies" icon={<CompaniesIcon />} />

        <Divider />
        <RoleWrapper roles={['app-admin']}>
          <NavigationExpandableItem title="Attributes" icon={<AttributesIcon />}>
            <NavigationItem title={t('operators_title')} link="/attributes/operators" />
            <NavigationItem title="Call categories" link="/attributes/categories" />
            <NavigationItem title="Contact groups" link="/attributes/groups" />
          </NavigationExpandableItem>
        </RoleWrapper>
        <NavigationItem title="Settings" link="/settings" icon={<SettingsIcon />} />
      </MenuList>

      <Typography className={styles.versionContainer} variant="caption">
        {process.env.REACT_APP_VERSION}
      </Typography>
    </Drawer>
  );
};

Navigation.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(Navigation);
