import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import Header from 'template/Header';
import Navigation from 'template/Navigation';
import Routes from 'template/Routes';
import Socketer from 'components/Socketer';
import ErrorBoundary from 'components/ErrorBoundary';
import styles from './Layout.module.scss';

const Layout = () => (
  <BrowserRouter>
    <ErrorBoundary>
      <Socketer>
        <div className={styles.appFrame}>
          <Header />
          <Navigation />
          <main className={styles.mainContent}>
            <Routes />
          </main>
        </div>
      </Socketer>
      <NotificationContainer />
    </ErrorBoundary>
  </BrowserRouter>
);

export default Layout;
