import { combineReducers } from 'redux';

import calls from './calls';
import user from './user';
import categories from './categories';
import companies from './companies';
import groups from './groups';
import contacts from './contacts';
import dashboard from './dashboard';
import settings from './settings';
import operators from './operators';
import resources from './resources';

export default combineReducers({
  calls,
  user,
  categories,
  companies,
  groups,
  contacts,
  dashboard,
  settings,
  operators,
  resources
});
