import i18n from 'logic/i18n';
import { NotificationManager } from 'react-notifications';

class Notification {
  static successUpdate(component) {
    NotificationManager.success(component, i18n.t('update_successful'), 1000);
  }

  static failureUpdate(component) {
    NotificationManager.error(component, i18n.t('update_failure'), 1000);
  }

  static callRing(contact, phoneNumber, callback) {
    NotificationManager.info(i18n.t('incoming_call_from', { contact }), phoneNumber, 6000, callback, 1);
  }

  static callEnded(contact, phoneNumber, callback) {
    NotificationManager.info(i18n.t('hangup_call_from', { contact }), phoneNumber, 0, callback, 0);
  }

  static deleteFailure(component, message) {
    NotificationManager.error(message, component);
  }

  static notificationDenied() {
    NotificationManager.error(i18n.t('notifications_denied'), i18n.t('notifications_denied_title'));
  }

  static genericFailure(component, message) {
    NotificationManager.error(message, component);
  }
}

export default Notification;
