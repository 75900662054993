import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import flow from 'lodash/flow';
import { withKeycloak } from '@react-keycloak/web';
import { Button, Menu, MenuItem } from '@material-ui/core';
import UserIcon from '@material-ui/icons/Person';
import { KEYCLOAK_SHAPE, HISTORY_SHAPE } from 'config/constants';

import styles from './Header.module.scss';

class ProfileButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null
    };
  }

  handleMenuClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogout = () => {
    this.props.keycloak.logout();
  };

  goToProfile = () => {
    this.props.history.push('/profile');
    this.handleClose();
  };

  render() {
    const { anchorEl } = this.state;
    const { keycloak } = this.props;

    return (
      <>
        <Button
          variant="contained"
          color="primary"
          startIcon={<UserIcon />}
          disableElevation
          onClick={this.handleMenuClick}
        >
          {keycloak.authenticated ? keycloak.tokenParsed.preferred_username : ''}
        </Button>

        <Menu className={styles.userMenu} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
          <MenuItem onClick={this.goToProfile}>Profile</MenuItem>
          <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
        </Menu>
      </>
    );
  }
}

ProfileButton.propTypes = {
  keycloak: PropTypes.shape(KEYCLOAK_SHAPE).isRequired,
  history: PropTypes.shape(HISTORY_SHAPE).isRequired
};

export default flow(withKeycloak, withRouter)(ProfileButton);
