import React from 'react';
import { Provider } from 'react-redux';
import store from 'logic/redux/store';
import Layout from 'template/Layout';
import DataProvider from 'template/DataProvider';
import LoginGuard from 'template/LoginGuard';

function App() {
  return (
    <Provider store={store}>
      <LoginGuard>
        <DataProvider>
          <Layout />
        </DataProvider>
      </LoginGuard>
    </Provider>
  );
}

export default App;
