import { fetch } from 'logic/middleware/api';
import apiUrlByName from 'helpers/urls';

// actions
export const SET_GROUPS = 'group/set-groups';
export const SET_GROUPS_LIST = 'group/set-groups-list';

// action creators
export const setGroups = (data, count) => ({
  type: SET_GROUPS,
  data,
  count
});

export const setGroupsList = (list) => ({
  type: SET_GROUPS_LIST,
  list
});

function buildDisplayGroup(body) {
  const { id, name } = body;

  return { id, data: { name } };
}

export const fetchGroups = (queryParams = {}) => async (dispatch) => {
  const result = await dispatch(fetch(apiUrlByName('group', {}, queryParams), {}));

  const data = result.data.map((item) => buildDisplayGroup(item));

  return dispatch(setGroups(data, result.totalCount));
};

export const fetchGroup = (id) => async (dispatch) => {
  try {
    const result = await dispatch(fetch(apiUrlByName('groupID', { id }), {}));

    return buildDisplayGroup(result);
  } catch (error) {
    return null;
  }
};

export const fetchGroupsList = (queryParams = {}) => async (dispatch) => {
  const result = await dispatch(fetch(apiUrlByName('group', {}, queryParams), {}));

  const data = result.data.map(({ id, name }) => ({ id, name }));

  return dispatch(setGroupsList(data));
};

export const addGroup = (body) => (dispatch) =>
  dispatch(
    fetch(apiUrlByName('group'), {
      method: 'post',
      body: JSON.stringify(body)
    })
  );

export const updateGroup = (id, body) => (dispatch) =>
  dispatch(
    fetch(apiUrlByName('groupID', { id }), {
      method: 'put',
      body: JSON.stringify(body)
    })
  );

export const removeGroups = (ids) => (dispatch) =>
  dispatch(
    fetch(apiUrlByName('group', {}, { ids: ids.join(',') }), {
      method: 'delete'
    })
  );
