import { v4 as uuidv4 } from 'uuid';
import CustomNotification from 'logic/Notification';

export const isNotificationApiPresent = () => {
  return 'Notification' in window;
};

export const areNotificationsAllowed = () => {
  return Notification.permission === 'granted';
};

export const requestNotificationPermission = async () => {
  let permitted = 'denied';
  if (Notification.permission !== 'denied') {
    permitted = await Notification.requestPermission();
    window.location.reload();
  } else {
    CustomNotification.notificationDenied();
  }
  return permitted === 'granted';
};

export const notify = (message) => {
  const notificationOptions = {
    body: message.body,
    title: message.title,
    badge: `${process.env.PUBLIC_URL}/salesviewer-logo.png`,
    image: `${process.env.PUBLIC_URL}/salesviewer-logo.png`,
    icon: `${process.env.PUBLIC_URL}/salesviewer-logo.png`,
    requireInteraction: true,
    vibrate: true,
    renotify: true,
    tag: uuidv4()
  };
  // eslint-disable-next-line no-new
  new Notification(message.title, notificationOptions);
};
