import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { isEqual, flow } from 'lodash';

import { Button, Typography } from '@material-ui/core';
import { AddBox as NewIcon, Refresh as RefreshIcon, Delete as DeleteIcon } from '@material-ui/icons';

import { POLLING_INTERVAL } from 'config/constants';
import Notification from 'logic/Notification';
import ContentView from 'components/ContentView';
import CustomTable from 'components/CustomTable';
import CustomDialog from 'components/CustomDialog';
import SearchField from 'components/SearchField';

import SideDrawer from './SideDrawer';
import styles from './CrudView.module.scss';

const POLLING_ACTIVATED = process.env.REACT_APP_POLLING_ACTIVATED;

class CrudView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      page: 0,
      rowsPerPage: 50,
      orderBy: props.defaultOrderBy || 'createdAt',
      orderDirection: props.defaultOrderDirection || 'desc',
      selectedItems: [],
      currentItem: null,
      searchString: '',
      fieldsChanged: false,
      showSideDrawer: false,
      openNotification: false,
      dialogType: '',
      dialogTitle: '',
      dialogContent: ''
    };

    this.filterColumns = props.headerLabels.map((item) => item.sortName);
    if (props.extendedFilterColumns) {
      this.filterColumns = [...this.filterColumns, ...props.extendedFilterColumns];
    }
  }

  componentDidMount() {
    const { stateOverride, preselectId } = this.props;

    if (stateOverride) {
      this.setState(stateOverride, () => {
        this.pollData();

        if (preselectId) {
          this.preselectItem(preselectId);
        }
      });
    } else {
      this.pollData();

      if (preselectId) {
        this.preselectItem(preselectId);
      }
    }
  }

  componentDidUpdate(prevProps) {
    // TODO: Horrible - needs to be refactored
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (this.props.preselectId) {
        this.preselectItem(this.props.preselectId);
      }
    }

    if (
      prevProps.extendedProps &&
      this.props.extendedProps &&
      !isEqual(prevProps.extendedProps.filterExtension, this.props.extendedProps.filterExtension)
    ) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    this.clearPolling();
  }

  get toolbarPrimary() {
    const {
      state: { loading, selectedItems, showSideDrawer },
      props: { addItem, removeItems, deletePersonalInformation, t }
    } = this;
    const selected = selectedItems.length;

    return (
      <>
        {typeof addItem !== 'undefined' && (
          <Button color="primary" startIcon={<NewIcon />} disabled={loading || showSideDrawer} onClick={this.newItem}>
            New
          </Button>
        )}
        {typeof removeItems !== 'undefined' && (
          <Button
            color="primary"
            startIcon={<DeleteIcon />}
            disabled={selectedItems.length === 0 || showSideDrawer}
            onClick={() => {
              this.openDialog('delete');
            }}
          >
            Delete
          </Button>
        )}
        {typeof deletePersonalInformation !== 'undefined' && (
          <Button
            color="primary"
            startIcon={<DeleteIcon />}
            disabled={selectedItems.length === 0 || showSideDrawer}
            onClick={() => {
              this.openDialog('delete_personal_information');
            }}
          >
            {t('delete_personal_information_button_label')}
          </Button>
        )}
        <Button
          color="primary"
          startIcon={<RefreshIcon />}
          disabled={loading || showSideDrawer}
          onClick={this.loadData}
        >
          Refresh
        </Button>
        <Typography className={styles.selectedMessage} variant="subtitle1">
          {selected > 0 && `${selected} selected`}
        </Typography>
      </>
    );
  }

  get toolbarSecondary() {
    const { searchString } = this.state;

    return <SearchField value={searchString} onSubmit={this.submitSearch} onClear={this.clearSearch} />;
  }

  get toolbarOptions() {
    const {
      toolbarExtensions: { toolbarOptions }
    } = this.props;

    return toolbarOptions || null;
  }

  get currentItemInfo() {
    const {
      state: { currentItem },
      props: { pageData }
    } = this;
    const item = pageData.find((obj) => obj.id === currentItem);

    if (typeof item === 'undefined') return null;

    return { id: item.id, data: { ...item.data } };
  }

  get filterItem() {
    const {
      state: { rowsPerPage, page, orderBy, orderDirection, searchString },
      props: {
        extendedProps: { filterExtension }
      }
    } = this;

    const filter = {
      limit: rowsPerPage,
      offset: page * rowsPerPage
    };

    if (orderBy) {
      Object.assign(filter, { orderBy, orderDirection });
    }

    if (searchString) {
      this.filterColumns.forEach((column) => {
        filter[`filter_${column}`] = searchString;
      });
    }

    if (filterExtension) {
      Object.keys(filterExtension).forEach((column) => {
        filter[`filterAnd_${column}`] = filterExtension[column];
        delete filter[`filter_${column}`];
      });
    }

    return filter;
  }

  closeSideDrawer = () => {
    const { fieldsChanged } = this.state;
    // TODO: Send close function from parent to enable custom functionality, maybe, or render based on match
    if (this.props.match && this.props.match.params.id) {
      this.props.history.replace('/calls');
    }

    if (fieldsChanged) {
      this.openDialog('unsaved');
    } else {
      this.setState({ currentItem: null, showSideDrawer: false });
    }
  };

  setFieldsChange = (value) => {
    this.setState({ fieldsChanged: value });
  };

  newItem = () => {
    const { fieldsChanged } = this.state;

    if (fieldsChanged) {
      this.openDialog('unsaved');
    } else {
      this.setState({ currentItem: null, showSideDrawer: true });
    }
  };

  saveItem = ({ id, data }) => {
    const { addItem, updateItem, t } = this.props;

    return this.setState({ fieldsChanged: false }, async () => {
      try {
        if (id) {
          await updateItem(id, data);
        } else {
          await addItem(data);
        }
      } catch (error) {
        Notification.genericFailure(t('generic_error_message'), error.detail || t('generic_error_message'));
      } finally {
        this.closeSideDrawer();
        this.loadData();
      }
    });
  };

  openDialog = (type) => {
    const { t } = this.props;
    switch (type) {
      case 'unsaved':
        this.setState({
          openNotification: true,
          dialogType: type,
          dialogTitle: 'Unsaved changes !',
          dialogContent: 'Do you want to lose your changes?'
        });
        break;
      case 'delete':
        this.setState({
          openNotification: true,
          dialogType: type,
          dialogTitle: 'Delete items!',
          dialogContent: 'Do you want to delete the selected items?'
        });
        break;
      case 'delete_personal_information':
        this.setState({
          openNotification: true,
          dialogType: type,
          dialogTitle: t('delete_contact_personal_information_title'),
          dialogContent: t('delete_contact_personal_information_content')
        });
        break;
      default:
        break;
    }
  };

  onDialogCancel = () => {
    this.setState({
      openNotification: false,
      dialogType: '',
      dialogTitle: '',
      dialogContent: ''
    });
  };

  onDialogConfirm = () => {
    const { dialogType } = this.state;

    switch (dialogType) {
      case 'unsaved':
        this.setState({
          openNotification: false,
          dialogType: '',
          dialogTitle: '',
          dialogContent: '',
          currentItem: null,
          showSideDrawer: false,
          fieldsChanged: false
        });
        break;
      case 'delete':
        {
          const { selectedItems } = this.state;

          this.setState(
            {
              openNotification: false,
              dialogType: '',
              dialogTitle: '',
              dialogContent: '',
              currentItem: null,
              showSideDrawer: false,
              fieldsChanged: false,
              selectedItems: []
            },
            () => {
              this.deleteSelectedItems(selectedItems);
            }
          );
        }
        break;
      case 'delete_personal_information':
        {
          const { selectedItems } = this.state;

          this.setState(
            {
              openNotification: false,
              dialogType: '',
              dialogTitle: '',
              dialogContent: '',
              currentItem: null,
              showSideDrawer: false,
              fieldsChanged: false,
              selectedItems: []
            },
            () => {
              this.deletePersonalInformationForSelectedItems(selectedItems);
            }
          );
        }
        break;
      default:
        break;
    }
  };

  submitSearch = (value) => {
    this.setState({ searchString: value }, this.loadData);
  };

  clearSearch = () => {
    this.setState({ searchString: '' }, this.loadData);
  };

  changeRowsPerPage = (event) => {
    this.setState(
      {
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
        selectedItems: []
      },
      this.loadData
    );
  };

  changePage = (event, page) => {
    this.setState({ page, selectedItems: [] }, this.loadData);
  };

  selectRow = (event) => {
    event.stopPropagation();
    const id = event.currentTarget.dataset.value;
    let { selectedItems } = this.state;
    if (selectedItems.indexOf(id) === -1) {
      selectedItems = selectedItems.concat([id]);
    } else {
      selectedItems = selectedItems.filter((item) => item !== id);
    }

    this.setState({ selectedItems });
  };

  selectAllRows = () => {
    const {
      state: { selectedItems },
      props: { pageData }
    } = this;

    this.setState({
      selectedItems: selectedItems.length < pageData.length ? pageData.map((item) => item.id) : []
    });
  };

  // TODO: Make function more generic
  preselectItem = (id) => {
    const { currentItem, fieldsChanged } = this.state;

    if (fieldsChanged) {
      this.openDialog('unsaved');
    } else {
      const newState =
        id === currentItem
          ? {
              currentItem: null,
              showSideDrawer: false
            }
          : {
              currentItem: id
            };

      if (currentItem === null) {
        newState.showSideDrawer = true;
      }

      this.setState(newState);
    }
  };

  selectCurrentItem = (event) => {
    const { currentItem, fieldsChanged } = this.state;

    const id = event.currentTarget.dataset.value;
    if (fieldsChanged) {
      this.openDialog('unsaved');
    } else {
      const newState =
        id === currentItem
          ? {
              currentItem: null,
              showSideDrawer: false
            }
          : {
              currentItem: id
            };

      if (currentItem === null) {
        newState.showSideDrawer = true;
      }

      this.setState(newState);
    }
  };

  requestSort = (event) => {
    const { orderBy, orderDirection } = this.state;
    const columnName = event.currentTarget.dataset.value;
    const newState = {};

    if (orderBy === columnName) {
      newState.orderDirection = orderDirection === 'asc' ? 'desc' : 'asc';
    } else {
      newState.orderBy = columnName;
      newState.orderDirection = 'asc';
    }

    this.setState(newState, this.loadData);
  };

  pollData = () => {
    this.loadData();
    this.pollingInterval = setInterval(() => {
      const { showSideDrawer } = this.state;
      if (!showSideDrawer && POLLING_ACTIVATED) {
        this.loadData();
      }
      return null;
    }, POLLING_INTERVAL);
  };

  clearPolling = () => {
    clearInterval(this.pollingInterval);
  };

  loadData = () => {
    const { getItems } = this.props;

    this.setState({ loading: true }, async () => {
      try {
        await getItems(this.filterItem);
      } catch (error) {
        // Stub
      } finally {
        this.setState({ loading: false });
      }
    });
  };

  async deleteSelectedItems(selectedItems) {
    const { removeItems, title } = this.props;
    try {
      await removeItems(selectedItems);
    } catch (error) {
      Notification.deleteFailure(title, error.detail);
    } finally {
      this.loadData();
    }
  }

  async deletePersonalInformationForSelectedItems(selectedItems) {
    const { deletePersonalInformation, title } = this.props;
    try {
      await deletePersonalInformation(selectedItems);
    } catch (error) {
      Notification.deleteFailure(title, error.detail);
    } finally {
      this.loadData();
    }
  }

  render() {
    const {
      state: {
        showSideDrawer,
        loading,
        page,
        rowsPerPage,
        orderBy,
        orderDirection,
        currentItem,
        selectedItems,
        openNotification,
        dialogTitle,
        dialogContent,
        fieldsChanged
      },
      props: { title, headerLabels, count, pageData, emptyData, fields, removeItems, disableFormCheck }
    } = this;
    return (
      <div className={styles.container}>
        <ContentView
          title={title}
          loading={loading}
          toolbarPrimary={this.toolbarPrimary}
          toolbarSecondary={this.toolbarSecondary}
          toolbarOptions={this.toolbarOptions}
        >
          <SideDrawer
            fields={fields}
            emptyData={emptyData}
            showDrawer={showSideDrawer}
            fieldsChanged={fieldsChanged}
            disableForm={disableFormCheck(this.currentItemInfo)}
            currentItemInfo={this.currentItemInfo}
            onSave={this.saveItem}
            onClose={this.closeSideDrawer}
            onFieldsChange={this.setFieldsChange}
          />

          <CustomTable
            headerLabels={headerLabels}
            page={page}
            count={count}
            orderBy={orderBy}
            pageData={pageData}
            rowsPerPage={rowsPerPage}
            currentItem={currentItem}
            selectedItems={selectedItems}
            orderDirection={orderDirection}
            hideCheckBox={typeof removeItems === 'undefined'}
            onSelectRow={this.selectRow}
            onChangePage={this.changePage}
            onRequestSort={this.requestSort}
            onSelectAllRows={this.selectAllRows}
            onChangeRowsPerPage={this.changeRowsPerPage}
            onSelectCurrentItem={this.selectCurrentItem}
          />

          <CustomDialog
            title={dialogTitle}
            open={openNotification}
            content={dialogContent}
            onCancel={this.onDialogCancel}
            onConfirm={this.onDialogConfirm}
          />
        </ContentView>
      </div>
    );
  }
}

CrudView.defaultProps = {
  count: 0,
  preselectId: '',
  toolbarExtensions: {},
  extendedProps: {},
  stateOverride: null,
  disableFormCheck: () => false,
  defaultOrderBy: 'createdAt',
  defaultOrderDirection: 'desc'
};

CrudView.propTypes = {
  title: PropTypes.string.isRequired,
  headerLabels: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])))
    .isRequired,
  pageData: PropTypes.arrayOf(PropTypes.object),
  count: PropTypes.number,
  preselectId: PropTypes.string,
  getItems: PropTypes.func.isRequired,
  addItem: PropTypes.func,
  updateItem: PropTypes.func.isRequired,
  removeItems: PropTypes.func,
  emptyData: PropTypes.func.isRequired,
  fields: PropTypes.objectOf(PropTypes.object).isRequired,
  toolbarExtensions: PropTypes.objectOf(PropTypes.object),
  extendedProps: PropTypes.objectOf(PropTypes.object),
  stateOverride: PropTypes.object,
  disableFormCheck: PropTypes.func,
  extendedFilterColumns: PropTypes.arrayOf(PropTypes.string),
  t: PropTypes.func.isRequired,
  deletePersonalInformation: PropTypes.func.isRequired,
  defaultOrderBy: PropTypes.string,
  defaultOrderDirection: PropTypes.string
};

export default flow(withTranslation(), withRouter)(CrudView);
