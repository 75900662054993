import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Tooltip, Collapse, List, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons/';

class NavigationExpandableItem extends Component {
  constructor(props) {
    super(props);

    this.state = { expand: false };
  }

  toggleExpand = () => {
    this.setState((prevState) => ({ expand: !prevState.expand }));
  };

  render() {
    const {
      state: { expand },
      props: { icon, title, children }
    } = this;

    return (
      <>
        <Tooltip title={title} placement="bottom-end" enterDelay={400}>
          <MenuItem onClick={this.toggleExpand}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={title} />
            {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </MenuItem>
        </Tooltip>

        <Collapse in={expand} timeout="auto" unmountOnExit>
          <List component="div" style={{ paddingLeft: 48 }}>
            {children}
          </List>
        </Collapse>
      </>
    );
  }
}

NavigationExpandableItem.propTypes = {
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
};

export default NavigationExpandableItem;
